import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      fill="none"
      viewBox="0 0 22 23"
    >
      <path
        fill="#4285F4"
        d="M21.99 11.469c0-.923-.076-1.596-.238-2.294H11.22v4.163h6.183c-.125 1.035-.798 2.593-2.294 3.64l-.02.14 3.33 2.58.23.023c2.12-1.957 3.341-4.837 3.341-8.252z"
      ></path>
      <path
        fill="#34A853"
        d="M11.22 22.438c3.029 0 5.572-.997 7.43-2.717l-3.541-2.743c-.947.66-2.219 1.122-3.89 1.122-2.966 0-5.484-1.957-6.382-4.662l-.131.011-3.463 2.68-.046.126c1.845 3.665 5.635 6.183 10.023 6.183z"
      ></path>
      <path
        fill="#FBBC05"
        d="M4.837 13.438a6.907 6.907 0 01-.374-2.219c0-.773.137-1.52.361-2.219l-.006-.148L1.31 6.128l-.114.055A11.23 11.23 0 000 11.219c0 1.808.436 3.516 1.197 5.036l3.64-2.817z"
      ></path>
      <path
        fill="#EB4335"
        d="M11.22 4.338c2.106 0 3.527.91 4.338 1.67l3.166-3.091C16.779 1.109 14.249 0 11.22 0 6.832 0 3.042 2.518 1.197 6.183L4.825 9c.91-2.705 3.428-4.662 6.395-4.662z"
      ></path>
    </svg>
  );
}

export default Icon;
