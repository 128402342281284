/** @format */

import React, { useState } from "react";

interface ReferenceImageProps {
   imageUrl: string;
   selectedImageStyle: any;
   isSelected: boolean;
}

const ReferenceImage = ({ imageUrl, selectedImageStyle, isSelected }: ReferenceImageProps) => {
   const [isLoaded, setIsLoaded] = useState(false);

   return (
      <div
         onClick={() => selectedImageStyle(imageUrl)}
         className={` ${isSelected ? "border-4 border-[#20E346]" : ""} rounded-2xl overflow-hidden w-full 2xl:h-[12rem] lg:h-[9rem] md:h-[8rem] h-[10rem]`}>
         <img
            onLoad={() => setIsLoaded(true)}
            className={`transition-opacity duration-1000 ease-in-out ${isLoaded ? "opacity-100" : "opacity-0"} w-full h-full rounded-xl`}
            src={imageUrl}
            alt="Reference image"
         />
      </div>
   );
};

export default ReferenceImage;
