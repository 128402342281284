/** @format */

import React, { useState, useEffect } from "react";
import { CancelOutlined } from "@mui/icons-material";
import HowItWorkWebOne from "assets/images/HowItWorkWebOne.png";
import HowItWorkWebTwo from "assets/images/HowItWorkWebTwo.png";
import HowItWorkWebThree from "assets/images/HowItWorkWebThree.png";
import HowItWorkWebFour from "assets/images/HowItWorkWebFour.png";
import FunArrowRight from "assets/icons/FunArrowRight";
import { useAppStore } from "store/useAppStore";
import { useLocation } from "react-router-dom";

const HowItWorksWeb = () => {
   const isNewUser = useAppStore().isNewUser;
   const updateNewUser = useAppStore().updateNewUser;
   const location = useLocation();

   const [open, setOpen] = useState(false);

   const showTourCards = [
      { title: "Upload/click a picture of your interior", image: HowItWorkWebOne, delay: 0, icon: <FunArrowRight /> },
      { title: "Select the type of the interior", image: HowItWorkWebTwo, delay: 1000, icon: <FunArrowRight /> },
      { title: "Select a style you want to generate", image: HowItWorkWebThree, delay: 2000, icon: <FunArrowRight /> },
      { title: " Click on “Generate Ideas”.", image: HowItWorkWebFour, delay: 3000 },
   ];

   useEffect(() => {
      if (isNewUser && location.pathname === "/uploadimage") {
         setOpen(true);
      }
      return () => {
         updateNewUser(false);
         setOpen(false);
      };
   }, []);

   const handleModal = () => {
      setOpen(!open);
   };
   return (
      <>
         {open && (
            <div className="w-full h-full p-[1rem] bg-[#000] bg-opacity-80 fixed z-50 top-0">
               <div className="z-200 p-[1rem] px-[2rem] pb-[3rem] absolute overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[95%]  bg-gray-500 bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-30 border-[0.2px] rounded-xl border-gray-100">
                  <div>
                     <div className="flex justify-between items-center mb-[1rem]">
                        <p className="font-semibold text-[1.3rem] text-white">How it works?</p>
                        <span onClick={handleModal}>
                           <CancelOutlined sx={{ color: "#fff" }} />
                        </span>
                     </div>
                     <div>
                        <div className="grid grid-cols-4 gap-[1rem] xl:ml-[2rem]">
                           {showTourCards.map((item, index) => (
                              <div
                                 style={{ animationDelay: `${index * 0.6}s` }}
                                 className={`flex items-center ${index === 3 ? "justify-start ml-[1rem]" : "justify-center"} fade-right`}>
                                 <div>
                                    <p className="text-white font-medium xl:text-center mb-2 h-[49px] xl:w-[15rem] w-[10rem]">
                                       <span className="font-semibold text-[0.9rem]">Step {index + 1} - </span> {item.title}
                                    </p>
                                    <div className="2xl:w-[15rem] lg:w-[12rem] w-[10rem] flex justify-center">
                                       <img
                                          className="2xl:w-[15rem] 2xl:h-[15rem] lg:h-[12rem] h-[10rem]"
                                          src={item.image}
                                          alt="demo"
                                       />
                                    </div>
                                 </div>
                                 <span className="2xl:ml-[2rem] max-xl:mt-[1rem]">{item.icon}</span>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </>
   );
};

export default HowItWorksWeb;
