/** @format */

import { Carousel } from "react-responsive-carousel";
import DemoOneBefore from "assets/icons/DemoOne.svg";
import DemoOneAfter from "assets/icons/DemoOneAfter.svg";
import BedroomBefore from "assets/images/BedroomBefore.png";
import BedroomAfter from "assets/images/BedroomAfter.png";
import RestroomBefore from "assets/images/RestroomBefore.png";
import RestroomAfter from "assets/images/RestroomAfter.png";
import KitchenAfter from "assets/images/KitchenAfter.png";
import KitchenBefore from "assets/images/KitchenBefore.png";
import CarouselCard from "./CarouselCard";
import CarouselCardWeb from "./CarouselCardWeb";

const index = () => {
   const innerWidth = window.innerWidth;

   return (
      <>
         {innerWidth < 769 ? (
            <Carousel
               showArrows={false}
               showStatus={false}
               showThumbs={false}
               showIndicators={true}
               infiniteLoop
               autoPlay
               interval={2500}>
               <CarouselCard
                  imageAfter={BedroomBefore}
                  imageBefore={BedroomAfter}
               />
               <CarouselCard
                  imageAfter={RestroomAfter}
                  imageBefore={RestroomBefore}
               />
               <CarouselCard
                  imageAfter={KitchenAfter}
                  imageBefore={KitchenBefore}
               />
            </Carousel>
         ) : (
            <Carousel
               showArrows={false}
               showStatus={false}
               showThumbs={false}
               showIndicators={true}
               infiniteLoop
               autoPlay
               interval={2500}>
               <CarouselCardWeb
                  imageAfter={BedroomAfter}
                  imageBefore={BedroomBefore}
               />
               <CarouselCardWeb
                  imageAfter={RestroomAfter}
                  imageBefore={RestroomBefore}
               />
               <CarouselCardWeb
                  imageAfter={KitchenAfter}
                  imageBefore={KitchenBefore}
               />
            </Carousel>
         )}
      </>
   );
};

export default index;
