import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="39"
      fill="none"
      viewBox="0 0 23 39"
    >
      <path
        fill="#fff"
        d="M17.566 37.325c.332-1.913-.256-3.86-.942-5.665a.092.092 0 01.005-.073.09.09 0 01.058-.045.175.175 0 01.191.078c.822 1.34 1.36 2.83 1.584 4.383 2.35-7.76 3.009-18.318-4.29-23.66-.368 2.333-1.13 4.73-2.877 6.43a7.003 7.003 0 01-3.638 1.999c-3.386.71-6.876-1.764-6.882-5.204-.01-6.243 7.773-8.382 13.025-5.523.37-3.303.301-6.66-.025-9.968a.069.069 0 01.06-.076.113.113 0 01.112.053.111.111 0 01.015.04c.51 3.396.722 6.841.453 10.272 8.689 5.035 8.532 17.21 4.694 25.204.59-1.228 2.594-1.888 3.646-1.864a.132.132 0 01.138.119.17.17 0 01-.09.151c-1.677.914-2.912 2.139-4.216 3.86-.535.706-1.276.96-1.021-.51zM11.715 11.07c-3.979-1.674-9.939.552-9.612 4.7.188 2.403 2.36 4.205 4.776 3.999a6.103 6.103 0 003.729-1.706c1.712-1.589 2.476-3.904 2.908-6.148a10.477 10.477 0 00-1.802-.845z"
      ></path>
    </svg>
  );
}

export default Icon;
