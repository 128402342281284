/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         fill="none"
         viewBox="0 0 18 18">
         <path
            fill="#fff"
            d="M9 17.25c-2.108 0-3.945-.699-5.511-2.096C1.923 13.756 1.026 12.01.796 9.917h1.879c.214 1.589.92 2.902 2.12 3.941 1.2 1.04 2.601 1.559 4.205 1.559 1.787 0 3.304-.623 4.55-1.868 1.245-1.244 1.867-2.76 1.867-4.549 0-1.787-.623-3.304-1.868-4.548C12.305 3.207 10.79 2.583 9 2.583c-1.054 0-2.04.245-2.956.734a6.812 6.812 0 00-2.315 2.016H6.25v1.834H.75v-5.5h1.833V3.82a8.003 8.003 0 012.854-2.269A8.187 8.187 0 019 .75a7.98 7.98 0 013.22.654 8.399 8.399 0 012.613 1.763 8.404 8.404 0 011.764 2.613c.436 1 .654 2.074.653 3.22a7.99 7.99 0 01-.653 3.22 8.37 8.37 0 01-1.764 2.613 8.405 8.405 0 01-2.613 1.764c-1 .436-2.074.654-3.22.653zm2.567-4.4L8.083 9.367v-4.95h1.834v4.216l2.933 2.934-1.283 1.283z"></path>
      </svg>
   );
}

export default Icon;
