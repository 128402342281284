/** @format */

import React, { useEffect } from "react";
import Lottie from "react-lottie-player";
import LoadingAnimation from "assets/animations/LoaderAnimation.json";

type Props = {
   isLoading: boolean;
   children?: React.ReactNode;
};

const Loader: React.FC<Props> = ({ isLoading }) => {
   useEffect(() => {
      if (isLoading) {
         document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "";
      }
      return () => {
         document.body.style.overflow = "";
      };
   }, [isLoading]);
   return (
      <>
         {isLoading && (
            <div
               style={{ background: "rgba(0,0,0,0.5)" }}
               className="w-full h-full top-0 fixed z-50 flex items-center justify-center">
               <div className="w-[17rem] h-[17rem] ">
                  <Lottie
                     loop
                     play
                     speed={2}
                     animationData={LoadingAnimation}
                     rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
                     style={{ width: "100%", borderRadius: "10px" }}
                  />
               </div>
            </div>
         )}
      </>
   );
};

export default Loader;
