/** @format */

import React, { useEffect, useState } from "react";
import Image from "components/Image";
import ModalClose from "assets/icons/ModalClose";
import ModalDownload from "assets/icons/ModalDownload";
import ModalShare from "assets/icons/ModalShare";
import { useAppStore } from "store/useAppStore";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface PreviewModalProps {
   downloadImage: (imageUrl: string) => void;
   shareImage: (imageUrl: string) => void;
   handleModalClose: () => void;
}

export default function PreviewModal({ downloadImage, shareImage, handleModalClose }: PreviewModalProps) {
   const generatedImageHistory: string[] = useAppStore().generatedImageHistory;
   const previewImageUrl = useAppStore().previewImageUrl;
   const [isLoaded, setIsLoaded] = useState(false);
   const [currentImageIndex, setCurrentImageIndex] = useState(0);
   console.log(generatedImageHistory, "generatedImageHistory");
   console.log(currentImageIndex, "currentImageIndex");

   useEffect(() => {
      const index = generatedImageHistory.indexOf(previewImageUrl);
      if (index !== -1) {
         setCurrentImageIndex(index);
      }
   }, [previewImageUrl, generatedImageHistory]);

   const navigateNext = () => {
      if (currentImageIndex < generatedImageHistory.length - 1) {
         setCurrentImageIndex(currentImageIndex + 1);
      }
   };

   const navigatePrevious = () => {
      if (currentImageIndex > 0) {
         setCurrentImageIndex(currentImageIndex - 1);
      }
   };

   const currentImageUrl = generatedImageHistory[currentImageIndex];

   return (
      <div className="bg-black bg-opacity-85  w-full h-[100vh] top-0 fixed z-10 left-0 right-0 flex justify-between items-center px-[5rem]">
         <span className="h-[80%] self-start my-auto ">
            <span
               onClick={handleModalClose}
               className="cursor-pointer">
               <ModalClose />
            </span>
         </span>
         <div className="2xl:w-[60%] xl:w-[60%] lg:w-[80%] h-[80%] flex items-center gap-5">
            <span
               className="cursor-pointer bg-[#222327] active:bg-[#000] rounded-full p-4 flex justify-center items-center"
               onClick={navigatePrevious}>
               <ArrowBackIosNewIcon
                  sx={{
                     color: "#fff",
                     fontSize: "2rem",
                  }}
               />
            </span>
            <img
               onLoad={() => setIsLoaded(true)}
               className={`transition-opacity duration-1000 ease-in-out ${isLoaded ? "opacity-100" : "opacity-0"} rounded-xl w-full h-full`}
               src={currentImageUrl}
               alt="image"
            />
            <span
               className="cursor-pointer bg-[#222327] active:bg-[#000] rounded-full p-4 flex justify-center items-center"
               onClick={navigateNext}>
               <ArrowForwardIosIcon
                  sx={{
                     color: "#fff",
                     fontSize: "2rem",
                  }}
               />
            </span>
         </div>
         <div className="flex flex-col gap-7 self-start h-[80%] my-auto">
            <span
               onClick={() => downloadImage(currentImageUrl)}
               className="cursor-pointer">
               <ModalDownload />
            </span>
            <span
               onClick={() => shareImage(currentImageUrl)}
               className="cursor-pointer">
               <ModalShare />
            </span>
         </div>
      </div>
   );
}
