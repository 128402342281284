import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 80 80"
    >
      <rect
        width="79"
        height="79"
        x="0.5"
        y="0.5"
        fill="#222327"
        rx="39.5"
      ></rect>
      <rect
        width="79"
        height="79"
        x="0.5"
        y="0.5"
        stroke="#222327"
        rx="39.5"
      ></rect>
      <path
        fill="#E1E1E1"
        d="M40 47l-8.75-8.75 2.45-2.538 4.55 4.55V26h3.5v14.263l4.55-4.55 2.45 2.537L40 47zm-10.5 7a3.369 3.369 0 01-2.471-1.027A3.377 3.377 0 0126 50.5v-5.25h3.5v5.25h21v-5.25H54v5.25c0 .962-.342 1.787-1.027 2.473-.685.686-1.51 1.028-2.473 1.027h-21z"
      ></path>
    </svg>
  );
}

export default Icon;
