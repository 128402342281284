/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="39"
         height="41"
         fill="none"
         viewBox="0 0 41 41">
         <path
            fill="#F04C4D"
            d="M31.761 24.104a.623.623 0 01-.47-.213 6.88 6.88 0 00-5.166-2.34c-1.463 0-2.916.48-4.091 1.352a.626.626 0 01-.744-1.004 8.185 8.185 0 014.836-1.597 8.132 8.132 0 016.106 2.764.627.627 0 01-.47 1.038z"></path>
         <path
            fill="#499ED7"
            d="M17.246 18.576a.624.624 0 01-.562-.897c.453-.936.69-1.962.691-3.002a6.894 6.894 0 00-.92-3.437.625.625 0 111.083-.625 8.135 8.135 0 01.273 7.61.629.629 0 01-.565.351z"></path>
         <path
            fill="#FFCC5C"
            d="M6.523 32.811l8.006-15.284a1.25 1.25 0 011.991-.303l6.434 6.433a1.249 1.249 0 01-.304 1.992L7.366 33.655a.625.625 0 01-.843-.844z"></path>
         <path
            fill="#F7B546"
            d="M22.955 23.657l-6.434-6.433a1.251 1.251 0 00-1.991.303l-.07.135 2.34 2.342a3.75 3.75 0 01-.91 5.973l-7.973 4.177-1.392 2.657a.625.625 0 00.843.844l15.284-8.006a1.25 1.25 0 00.303-1.992z"></path>
         <path
            fill="#F47423"
            d="M10.412 32.059l1.566-.82-3.981-1.244-.589 1.125 3.004.939zm-1.438-3.93l5.6 1.75 1.567-.82-6.577-2.055-.59 1.125zm1.567-2.992l8.195 2.562 1.566-.82-9.171-2.867-.59 1.125zm1.567-2.99l10.716 3.35a1.216 1.216 0 00.452-1.17l-10.578-3.305-.59 1.126zm1.566-2.99l6.95 2.172-1.904-1.904-4.457-1.393-.588 1.125z"></path>
         <path
            fill="#ED5D25"
            d="M23.274 24.328l-5.397-1.687c.001.425-.064.845-.203 1.247l5.15 1.608c.175-.14.3-.315.38-.506l.017-.044c.07-.198.089-.411.053-.618zm-7.133 4.73l-3.844-1.2-1.566.82 3.844 1.2.365-.19 1.2-.63zm.657-9.054c.054.054.087.116.135.172l3.69 1.154-1.905-1.905-3.636-1.136 1.716 1.715zM7.408 31.12l3.004.939 1.566-.82-3.844-1.201-.22.116-.506.966zm8.479-5.142l-.994.52 3.844 1.202 1.566-.82-3.937-1.23c-.157.113-.303.235-.479.328z"></path>
         <path
            fill="#92CA62"
            d="M10.5 16.552a1.877 1.877 0 01-1.397-3.124 1.867 1.867 0 010-2.5A1.878 1.878 0 0110.5 7.802a.625.625 0 110 1.25.625.625 0 000 1.25.625.625 0 110 1.25.625.625 0 100 1.25.625.625 0 110 1.25.625.625 0 100 1.25.625.625 0 110 1.25z"></path>
         <path
            fill="#71BC58"
            d="M10.541 11.544a.616.616 0 00.543-.41.62.62 0 00-.543.41zm.543-2.91a.62.62 0 00-.543.41.616.616 0 00.543-.41zM9.25 14.25c0-.48.181-.918.479-1.25a1.867 1.867 0 010-2.5 1.867 1.867 0 01.053-2.554 1.876 1.876 0 00-.678 2.982 1.866 1.866 0 000 2.5 1.878 1.878 0 001.396 3.124.618.618 0 00.586-.43A1.875 1.875 0 019.25 14.25z"></path>
         <path
            fill="#71BC58"
            d="M10.541 14.044a.617.617 0 00.541-.41.619.619 0 00-.541.41z"></path>
         <path
            fill="#AE89BE"
            d="M27.666 17.876c-.48 0-.96-.182-1.326-.547a.625.625 0 01.884-.885.625.625 0 10.883-.885.623.623 0 01.442-1.068c.166 0 .325.066.442.183a.624.624 0 00.884-.884.622.622 0 010-.885.625.625 0 01.884 0 .643.643 0 00.883 0 .618.618 0 00.183-.442.62.62 0 00-.183-.442.625.625 0 11.884-.885 1.874 1.874 0 01-1.223 3.199 1.87 1.87 0 01-1.768 1.769 1.867 1.867 0 01-.547 1.222c-.36.368-.842.55-1.322.55z"></path>
         <path
            fill="#9676B5"
            d="M28.313 14.678a.617.617 0 00.782.068c-.034-.022-.073-.037-.103-.068a.617.617 0 00-.781-.07c.034.023.072.04.102.07zm1.768-1.768a.632.632 0 00.781.066c-.035-.022-.072-.036-.102-.066a.617.617 0 00-.782-.069c.034.023.073.04.103.07zm-3.535 3.535a.617.617 0 00.781.069c-.034-.023-.072-.038-.102-.069a.618.618 0 00-.782-.067c.034.022.073.037.103.067z"></path>
         <path
            fill="#9676B5"
            d="M32.527 11.141a.618.618 0 00-.782-.067c.034.022.073.037.103.067a1.874 1.874 0 01-1.223 3.199 1.87 1.87 0 01-1.768 1.769 1.867 1.867 0 01-.546 1.222c-.278.278-.627.415-.987.481.114.022.224.067.339.067a1.871 1.871 0 001.872-1.77 1.873 1.873 0 001.77-1.77 1.872 1.872 0 001.77-1.872 1.859 1.859 0 00-.548-1.326z"></path>
         <path
            fill="#499ED7"
            d="M20.718 32.752a.625.625 0 01-.537-.306 1.872 1.872 0 011.975-2.796c.134-.425.419-.805.831-1.049a1.86 1.86 0 011.319-.226c.137-.441.43-.811.831-1.049a1.857 1.857 0 011.421-.204c.485.124.894.43 1.15.86a.626.626 0 01-1.078.638.623.623 0 10-1.074.637.628.628 0 01-1.075.64.627.627 0 00-1.144.164.628.628 0 00.068.474.626.626 0 01-1.078.637.614.614 0 00-.382-.286.625.625 0 00-.692.924.623.623 0 01-.535.942z"></path>
         <path
            fill="#2B8EC6"
            d="M20.888 32.448a1.872 1.872 0 011.265-2.797 1.846 1.846 0 00-1.316.227 1.865 1.865 0 00-.86 1.148 1.863 1.863 0 00.205 1.422.628.628 0 00.856.218c.017-.008.024-.025.039-.035a.646.646 0 01-.189-.183zm2.149-1.277a.613.613 0 00-.383-.286.618.618 0 00-.473.069c-.013.007-.02.02-.032.027.072.05.136.113.18.19a.626.626 0 00.858.22c.012-.008.016-.022.029-.03a.622.622 0 01-.179-.19zm-.172-1.52c.133-.425.418-.805.83-1.048a1.86 1.86 0 011.32-.227 1.854 1.854 0 011.428-1.27 1.854 1.854 0 00-2.135 1.27 1.854 1.854 0 00-1.319.227 1.864 1.864 0 00-.83 1.047c.23-.044.468-.047.706.001zm2.325.245a.627.627 0 00-.858-.218c-.012.007-.02.02-.031.028.071.05.134.112.181.192a.625.625 0 00.856.217c.013-.007.017-.021.028-.029a.627.627 0 01-.177-.19zm2.148-1.275a.624.624 0 00-.856-.217c-.012.007-.02.02-.031.027.071.05.135.113.18.19a.626.626 0 00.857.22c.013-.008.019-.02.032-.028a.617.617 0 01-.182-.192z"></path>
         <path
            fill="#AE89BE"
            d="M19.25 8.427a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"></path>
         <path
            fill="#499ED7"
            d="M29.25 8.427a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"></path>
         <path
            fill="#FFCC5C"
            d="M34.25 20.927a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"></path>
         <path
            fill="#F04C4D"
            d="M5.5 25.927a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"></path>
         <path
            fill="#9676B5"
            d="M19.25 5.927c-.16.002-.32.034-.469.095a1.246 1.246 0 010 2.31c.149.062.308.094.469.095a1.25 1.25 0 000-2.5z"></path>
         <path
            fill="#2B8EC6"
            d="M29.25 5.927c-.16.002-.32.034-.469.095a1.246 1.246 0 010 2.31c.149.062.308.094.469.095a1.25 1.25 0 000-2.5z"></path>
         <path
            fill="#F7B546"
            d="M34.25 18.427c-.16.002-.32.034-.469.096a1.246 1.246 0 010 2.31c.149.061.308.093.469.095a1.25 1.25 0 000-2.5z"></path>
         <path
            fill="#E13340"
            d="M5.5 23.427c-.16.002-.32.034-.469.096a1.246 1.246 0 010 2.31c.149.061.308.093.469.095a1.25 1.25 0 000-2.5z"></path>
         <path
            fill="#F04C4D"
            d="M30.5 33.428a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"></path>
         <path
            fill="#E13340"
            d="M30.5 30.927c-.16.002-.32.034-.469.096a1.246 1.246 0 010 2.31c.149.061.308.093.469.095a1.25 1.25 0 000-2.5z"></path>
         <path
            fill="#F04C4D"
            d="M33.526 27.85a.625.625 0 01-.616-.526 6.656 6.656 0 00-.306-1.195.625.625 0 111.18-.415c.161.46.282.932.361 1.412a.627.627 0 01-.619.724z"></path>
         <path
            fill="#499ED7"
            d="M15.52 9.729a.623.623 0 01-.416-.159 7.3 7.3 0 00-.534-.432.625.625 0 01.743-1.005c.215.159.423.328.623.507a.625.625 0 01-.416 1.089z"></path>
         <path
            fill="#92CA62"
            d="M21.492 19.073a.624.624 0 01-.454-1.057 9.331 9.331 0 002.585-6.465 9.428 9.428 0 00-.949-4.108.623.623 0 01.518-.922.625.625 0 01.605.374 10.67 10.67 0 011.075 4.658 10.575 10.575 0 01-2.93 7.326.611.611 0 01-.45.194zM10.5 19.052a.625.625 0 100-1.25.625.625 0 000 1.25z"></path>
      </svg>
   );
}

export default Icon;
