/** @format */

import React, { useEffect, useState } from "react";
import DownloadIcon from "../../assets/icons/DownloadIcon";
import ShareIcon from "../../assets/icons/ShareIcon";
import { useAppStore } from "store/useAppStore";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { handleDownload, handleShareFile } from "utils/helper";

const SaveImage = () => {
   const generatedImageUrl = useAppStore()?.generatedImageUrl;
   const UploadedImageUrl = useAppStore()?.uploadedImageUrl;
   const getRemainingCredits = useAppStore().getRemainingCredits;
   const innerHeight = window.innerHeight;
   const navigate = useNavigate();
   const [isLoaded, setIsLoaded] = useState(false);

   const handleDownloadImage = () => {
      handleDownload(generatedImageUrl);
   };
   const handleShareFileImage = () => {
      handleShareFile(generatedImageUrl);
   };

   const handlePreviewImage = () => {
      navigate(`/preview/true`);
   };

   useEffect(() => {
      getRemainingCredits();
   }, []);

   return (
      <div className={`w-full ${innerHeight > 730 ? "h-[90vh]" : "h-[100vh]"} items-center relative flex flex-col gap-[1rem]  mt-[4.5rem]`}>
         <div className="w-full h-full flex flex-col justify-around pb-[5.5rem]">
            <div className="w-[100%]">
               <p className="text-[#fff] mb-[0.5rem] w-[90%] mx-auto text-center">Actual Image</p>
               <div className="w-[75%] h-[17rem] mx-auto ">
                  <img
                     className="rounded-md h-full w-full"
                     src={UploadedImageUrl}
                     alt="Generated Image"
                  />
               </div>
            </div>
            <div className="w-[100%]">
               <p className="text-[#fff] mb-[0.5rem] w-[90%] mx-auto text-center">Generated Image</p>
               <div className="w-[75%] h-[17rem] mx-auto ">
                  <img
                     onClick={handlePreviewImage}
                     onLoad={() => setIsLoaded(true)}
                     className={`transition-opacity duration-700 ease-in-out ${isLoaded ? "opacity-100" : "opacity-0"} rounded-md h-full w-full `}
                     src={generatedImageUrl}
                     alt="Generated Image"
                  />
               </div>
            </div>
         </div>
         <div className="flex gap-[1rem] w-[90%] mx-auto fixed bottom-3">
            <Button
               buttonText={"Download"}
               icon={<DownloadIcon />}
               onclick={handleDownloadImage}
            />
            <Button
               buttonText={"Share"}
               icon={<ShareIcon />}
               onclick={handleShareFileImage}
            />
         </div>
      </div>
   );
};

export default SaveImage;
