/** @format */

import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

interface ToogleButtonProps {
   currencyCheck: boolean;
   handleToggle: (currency: string) => void;
}

export default function ColorToggleButton({ currencyCheck, handleToggle }: ToogleButtonProps) {
   const [currentCurreny, setCurrentCurrency] = React.useState("USD");

   const commonButtonStyle = {
      fontWeight: 600,
      fontSize: "1.2rem",

      width: "100%",
      border: "3px solid #fff",
   };
   const handleChange = (e: any) => {
      const selectedCurrency = e.target.value;
      setCurrentCurrency(selectedCurrency);
      handleToggle(selectedCurrency);
   };
   return (
      <div className="flex justify-center md:w-[20rem] h-[3rem]">
         <ToggleButtonGroup
            style={{ width: "100%" }}
            color="primary"
            value={currentCurreny}
            exclusive
            onChange={(e) => handleChange(e)}
            aria-label="Platform">
            <ToggleButton
               className=""
               style={{
                  ...commonButtonStyle,
                  backgroundColor: currencyCheck ? "#000" : "#fff",
                  color: currencyCheck ? "#fff" : "#000",
                  borderTopLeftRadius: "50px",
                  borderBottomLeftRadius: "50px",
               }}
               value="USD">
               USD
            </ToggleButton>
            <ToggleButton
               style={{
                  ...commonButtonStyle,
                  backgroundColor: currencyCheck ? "#fff" : "#000",
                  color: currencyCheck ? "#000" : "#fff",
                  borderTopRightRadius: "50px",
                  borderBottomRightRadius: "50px",
               }}
               value="INR">
               INR
            </ToggleButton>
         </ToggleButtonGroup>
      </div>
   );
}
