/** @format */

import * as React from "react";
import { useState, useEffect } from "react";

interface CategoryChipsProps {
   categorys: string;
   isSelected?: boolean;
   handleCategory?: any;
}

const CategoryChips = ({ categorys, isSelected, handleCategory }: CategoryChipsProps) => {
   const formattedCategory = categorys.charAt(0).toUpperCase() + categorys.slice(1).replace("_", " ");

   return (
      <button
         onClick={() => handleCategory(categorys)}
         className={`${
            isSelected ? "bg-white text-[#000] border" : "bg-black text-[#fff] border-[#fff] border"
         } hover:bg-[#fff] hover:text-black transition-colors duration-300 font-semibold rounded-full text-[13px] px-1.5 py-1 active:bg-gray-200`}>
         {formattedCategory}
      </button>
   );
};

export default CategoryChips;
