/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="18"
         height="18"
         fill="none"
         viewBox="0 0 18 22">
         <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M12.75 8h1.875A1.875 1.875 0 0116.5 9.875v9a1.875 1.875 0 01-1.875 1.875H3.375A1.875 1.875 0 011.5 18.875v-9A1.875 1.875 0 013.375 8H5.25m7.5-3L9 1.25m0 0L5.25 5M9 1.25v12.797"></path>
      </svg>
   );
}

export default Icon;
