import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="39"
      fill="none"
      viewBox="0 0 42 39"
    >
      <path
        fill="#4949E7"
        d="M41.167 0H14C6.268 0 0 6.268 0 14v25L41.167 0z"
      ></path>
      <mask
        id="mask0_1919_1591"
        style={{ maskType: "alpha" }}
        width="14"
        height="13"
        x="6"
        y="6"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#D9D9D9"
          d="M6.334 6H19.506999999999998V18.48H6.334z"
        ></path>
      </mask>
      <g mask="url(#mask0_1919_1591)">
        <path
          fill="#fff"
          d="M10.877 16.67a.667.667 0 01-.978-.737l.524-2.146-1.72-1.413a.667.667 0 01.37-1.18l2.31-.19.929-2.08a.667.667 0 011.217 0l.928 2.08 2.312.19c.597.05.831.8.369 1.18l-1.72 1.413.524 2.146a.667.667 0 01-.978.737l-2.043-1.167-2.044 1.167z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
