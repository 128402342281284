/** @format */

import React, { useState } from "react";
import Image from "components/Image";
import LoginBackgroundImage from "assets/images/LoginImageMobile.png";
import CarouselOverlay from "assets/icons/CarouselOverlay.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { ROUTE_BASED_URL } from "definition";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "store/useAppStore";
import Carousel from "components/Carousel";
import Loader from "components/Loader";
import LoginImage from "assets/images/LoginImage.png";
import LoginButton from "components/LoginButton";
import ImageOverlayText from "components/Login/ImageOverlayText";

const Login = () => {
   const navigate = useNavigate();
   const SetCredits = useAppStore().setCredits;
   const updateNewUser = useAppStore().updateNewUser;
   const innerHeight = window.innerHeight;
   const innerWidth = window.innerWidth;
   const [loading, setLoading] = useState(false);

   const handleLogin = useGoogleLogin({
      onSuccess: async (response) => {
         SIGN_IN(response);
      },
   });

   const SIGN_IN = async (response: any) => {
      setLoading(true);
      await fetch(`${ROUTE_BASED_URL}imagine_interior/signup/send_email`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${response.access_token}`,
         },
      })
         .then(async (res) => {
            if (res.status === 200 || res.status === 201) {
               const response = await res.json();
               localStorage.setItem("token", response.jwt);
               updateNewUser(response.new_user);
               if (localStorage.getItem("token") !== "undefined") {
                  setLoading(false);
                  window.location.href = "/uploadimage";
               }
               SetCredits(response.remaining_credits);
            }
         })
         .catch((err) => {
            setLoading(false);
         });
   };

   return (
      <>
         {innerWidth <= 768 ? (
            <div className="flex flex-col justify-between h-[100vh]">
               <Loader isLoading={loading} />
               <div className="w-full max-h-[26rem]  relative">
                  <Image
                     imageSrc={LoginBackgroundImage}
                     altText="Login Image"
                  />
                  <div className="w-full mx-auto absolute bottom-[2.2rem]">
                     <ImageOverlayText />
                  </div>
               </div>
               <LoginButton handleClick={() => handleLogin()} />
               <div className={`px-[1rem] ${innerHeight > 699 && "mb-[3rem]"} sm:mb-[1rem]  relative`}>
                  <img
                     className="-z-50 absolute w-[90%] h-[100%] mt-[]"
                     src={CarouselOverlay}
                     alt="overlay"
                  />
                  <h2 className="text-white font-semibold text-[1.3rem] mb-[1.5rem] text-center">Imagined Inspirations</h2>
                  <Carousel />
               </div>
            </div>
         ) : (
            <div className="relative flex w-[100%] h-[100vh]">
               <Loader isLoading={loading} />
               <div
                  className="w-[50%] h-full"
                  style={{ backgroundImage: `url(${LoginImage})`, backgroundSize: "cover" }}>
                  <div className="w-full flex justify-center mt-[3rem] text-center">
                     <ImageOverlayText />
                  </div>
               </div>
               <div className="w-[50%] h-full flex flex-col justify-around">
                  <div>
                     <h2 className="text-white font-semibold xl:text-[1.8rem] text-[1.5rem] lg:mb-[4rem] mb-[3rem] tracking-wide text-center">Imagined Inspirations</h2>
                     <Carousel />
                  </div>
                  <LoginButton
                     className="!rounded-xl text-[1.4rem] mt-[-1rem]"
                     handleClick={() => handleLogin()}
                  />
               </div>
            </div>
         )}
      </>
   );
};

export default Login;
