/** @format */

import React, { useState } from "react";
import { useAppStore } from "store/useAppStore";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useParams } from "react-router-dom";

const Preview = () => {
   const { generatedimage } = useParams();
   const previewImageUrl = useAppStore().previewImageUrl;
   const generatedImageUrl = useAppStore().generatedImageUrl;
   const [isLoaded, setIsLoaded] = useState(false);

   return (
      <div className="h-[100vh]">
         <div className="bg-[#222327] w-full h-[7vh] flex justify-end items-center px-[0.8rem]">
            <Link to={`${generatedimage ? "/saveimage" : "/history"}`}>
               <CloseIcon sx={{ color: "#fff", fontSize: "2rem" }} />
            </Link>
         </div>
         <div className="h-[93vh] flex justify-center items-center mt-[-2rem]">
            <img
               onLoad={() => setIsLoaded(true)}
               className={`transition-opacity duration-700 delay-300 ease-in-out ${isLoaded ? "opacity-100" : "opacity-0"} w-full h-[65%]`}
               src={generatedimage ? generatedImageUrl : previewImageUrl}
               alt="image"
            />
         </div>
      </div>
   );
};

export default Preview;
