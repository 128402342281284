/** @format */

import React, { useState } from "react";

interface GradientButtonProps {
   buttonText: string;
   icon?: any;
   classname: string;
   onclick: () => void;
   disabled: boolean;
}

const GradientButton = ({ buttonText, icon, classname, onclick, disabled }: GradientButtonProps) => {
   return (
      <div
         className={`${classname} p-1 rounded-full bottom-2 !mx-auto`}
         style={{ backgroundImage: icon ? "" : "linear-gradient(to right, #FE53BB, #09FBD3)", position: "fixed" }}>
         <button
            disabled={disabled}
            onClick={onclick}
            className="flex justify-center items-center gap-2 p-[0.5rem] w-full h-full text-white bg-black rounded-full active:bg-[#1c1c1c]">
            <span className="mt-[-0.2rem]">{icon}</span>
            {buttonText}
         </button>
         {disabled && (
            <div
               className="md:hidden w-[100%] h-full bg-black opacity-60 bottom-0 left-0 right-0 mx-auto rounded-full"
               style={{ position: "absolute" }}></div>
         )}
      </div>
   );
};

export default GradientButton;
