import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      fill="none"
      viewBox="0 0 16 12"
    >
      <path
        fill="url(#paint0_linear_1919_1445)"
        d="M10 .667a5.333 5.333 0 110 10.667A5.333 5.333 0 0110 .667zM10 10a4 4 0 100-8 4 4 0 000 8zM2 6a3.993 3.993 0 002.666 3.767v1.393a5.33 5.33 0 01-4-5.16 5.33 5.33 0 014-5.16v1.393A3.994 3.994 0 001.999 6z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1919_1445"
          x1="6.732"
          x2="12.895"
          y1="0.667"
          y2="3.349"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE53BB"></stop>
          <stop offset="1" stopColor="#09FBD3"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;
