/** @format */

import React from "react";

interface ImageProps {
   imageSrc: any;
   altText: string;
   className?: string;
}

const Image = ({ imageSrc, altText, className }: ImageProps) => {
   return (
      <img
         className={`${className} w-full h-full`}
         src={imageSrc}
         alt={altText}
      />
   );
};

export default Image;
