/** @format */

import React, { useEffect, useState } from "react";
import PurchaseCard from "../../components/PurchaseCard";
import { useAppStore } from "store/useAppStore";
import { PricingDetailProps } from "interfaces/getItem/GetPricing";
import { PurchaseCreditProps } from "interfaces/payloads/PurchaseCredit";
import { PostAPI } from "api/request";
import { generateRazorpayPayload } from "utils/razorpay";
import { CreateCreditProps } from "interfaces/payloads/CreateCredits";
import { StripePaymentProps } from "interfaces/payloads/StripePayment";
import useRazorpay from "react-razorpay";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CurrencyToggler from "components/CurrencyToggler";

const Purchase = () => {
   const Razorpay = useRazorpay();
   const location = useLocation();
   const navigate = useNavigate();
   const getPricing = useAppStore().getPricing;
   const updateCredits = useAppStore().updateCredits;
   const setPricingCurrency = useAppStore().setPricingCurrency;
   const pricingCurreny = useAppStore().pricingCurrency;
   const paymentId = useParams().paymentId;

   const [pricingDetailINR, setPricingDetailsINR] = useState<PricingDetailProps[]>([]);
   const [pricingDetailUSD, setPricingDetailsUSD] = useState<PricingDetailProps[]>([]);

   useEffect(() => {
      getPricing().then((response: any) => {
         setPricingDetailsINR(response.data.INR);
         setPricingDetailsUSD(response.data.USD);
      });
   }, []);

   // RazorPay Payment

   const buyCreditsUsingRazorpay = async (pricingId: string) => {
      const payload: PurchaseCreditProps = { pricing_id: pricingId };
      await PostAPI("imagine_interior/subscriptions/attempt_credit_purchase", payload)
         .then((res) => {
            openRazorypay(res);
         })
         .catch((err) => {
            alert("Something went wrong");
         });
   };

   const openRazorypay = async (attemptResponse: any) => {
      const payload = generateRazorpayPayload(attemptResponse);
      const razorpayPayload = {
         ...payload,
         handler: function (response: any) {
            CreateCreditsPurchase(attemptResponse);
         },
      };
      const rzp1 = new Razorpay(razorpayPayload);
      rzp1.on("payment.failed", function (response: any) {
         alert("Payment is failed!");
      });
      rzp1.open();
   };

   const CreateCreditsPurchase = async (razorpayPayload: any) => {
      const payload: CreateCreditProps = {
         credit_purchase_id: razorpayPayload?.data?.credit_purchase_id,
         payment_gateway_order_id: razorpayPayload?.data?.payment_gateway_order_id,
         payment_status: "paid",
      };
      try {
         PostAPI("imagine_interior/subscriptions/create_credit_purchase", payload).then(() => {
            updateCredits();
            navigate(`/uploadimage`);
         });
      } catch (error) {
         console.log(error);
      }
   };

   const handleBuyCredit = (pricingId: string) => {
      pricingCurreny ? buyCreditsUsingRazorpay(pricingId) : buyCreditsUsingStripe(pricingId);
   };

   // StripePayment Functions

   const buyCreditsUsingStripe = async (pricingId: string) => {
      const payload = {
         pricing_id: pricingId,
      };
      PostAPI("imagine_interior/subscriptions/stripe_checkout", payload)
         .then((response: any) => {
            const checkOutSessionUrl = response?.data?.checkout_session_url;
            if (checkOutSessionUrl) {
               window.location.href = checkOutSessionUrl;
            }
         })
         .catch((err) => {
            alert("Something went wrong");
         });
   };

   const sendPaymentStatus = async () => {
      if (paymentId === undefined || paymentId === null) return;
      if (location.pathname === `/success/${paymentId}` || location.pathname === `/cancelled/${paymentId}`) {
         const payload: StripePaymentProps = {
            checkout_id: paymentId,
            payment_status: location.pathname === `/success/${paymentId}` ? "success" : "failed",
         };
         PostAPI("imagine_interior/subscriptions/stripe_payment_status", payload).then(() => {
            updateCredits();
            navigate(`/uploadimage`);
         });
      } else {
         return;
      }
   };

   useEffect(() => {
      sendPaymentStatus();
   }, []);

   const handleCheck = (currency: string) => {
      if (currency === "INR") {
         setPricingCurrency(true);
      } else {
         setPricingCurrency(false);
      }
   };

   const selecetedPricingDetails = () => {
      return pricingCurreny ? pricingDetailINR : pricingDetailUSD;
   };

   return (
      <div className="md:mt-[8rem] mt-[4rem] md:w-[70%] lg:w-[50%] w-[95%] mx-auto">
         <div className="flex max-md:flex-col w-full justify-between md:items-center">
            <h2 className="text-[#fff]  font-semibold md:text-[1.7rem] text-[1.5rem] mt-[1rem] mb-[1rem]">Purchase credits</h2>
            <CurrencyToggler
               currencyCheck={pricingCurreny}
               handleToggle={(currency) => handleCheck(currency)}
            />
         </div>
         <div className="mt-[2rem] ">
            {selecetedPricingDetails()?.map((item, index) => (
               <PurchaseCard
                  key={index}
                  handleClick={() => handleBuyCredit(item.pricing_id)}
                  credits={item.number_of_credits}
                  amount={item.discounted_value}
                  totalImages={item.no_of_images}
                  currency={item.currency}
                  recommended={item?.recommended}
               />
            ))}
         </div>
      </div>
   );
};

export default Purchase;
