import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect
        width="31"
        height="31"
        x="0.5"
        y="0.5"
        stroke="#fff"
        rx="15.5"
      ></rect>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M20.875 11.125l-9.75 9.75m0-9.75l9.75 9.75"
      ></path>
    </svg>
  );
}

export default Icon;
