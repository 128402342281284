/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="32"
         height="32"
         fill="none"
         viewBox="0 0 27 27">
         <rect
            width="25.667"
            height="25.667"
            x="0.666"
            y="0.833"
            stroke="#fff"
            rx="12.833"></rect>
         <path
            fill="#fff"
            d="M12.833 16.333V10.9l-1.734 1.733-.933-.966 3.333-3.334 3.334 3.334-.934.966-1.733-1.733v5.433h-1.333zM9.499 19c-.366 0-.68-.13-.941-.391a1.286 1.286 0 01-.392-.942v-2h1.333v2h8v-2h1.334v2c0 .366-.13.68-.392.942a1.28 1.28 0 01-.942.39h-8z"></path>
      </svg>
   );
}

export default Icon;
