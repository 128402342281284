/** @format */

export const handleDownload = async (imageUrl: string) => {
   try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "GeneratedImage.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
   } catch (error) {
      console.error("Error downloading the image:", error);
   }
};

export const handleShareFile = async (imageUrl: string) => {
   if (!navigator.share) {
      alert("Web Share API is not supported in this browser.");
      return;
   }
   try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], "GeneratedImage.png", { type: blob.type });
      const shareText = "This interior was imagined using Imagine your interior.";

      if (navigator.canShare && navigator.canShare({ files: [file], title: "Generated Image", text: shareText })) {
         await navigator.share({
            files: [file],
            title: "Generated Image",
            text: shareText,
            url: "https://interior.pixelgen.art/",
         });
         console.log("Content shared successfully");
      } else {
         alert("File sharing is not supported. Please copy the text and share the image manually.");
      }
   } catch (error) {
      console.error("Error sharing the image:", error);
   }
};
