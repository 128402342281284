import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="50"
      fill="none"
      viewBox="0 0 80 50"
    >
      <path
        fill="#fff"
        d="M77.762 12.361c-3.986-.693-8.041.534-11.802 1.963a.188.188 0 01-.212-.061.192.192 0 01-.035-.07.364.364 0 01.163-.4 24.022 24.022 0 019.132-3.299c-16.169-4.897-38.164-6.268-49.293 8.937 4.86.767 9.855 2.355 13.394 5.994a14.59 14.59 0 014.166 7.579c1.481 7.055-3.675 14.325-10.842 14.338-13.007.022-17.462-16.192-11.506-27.134-6.881-.772-13.876-.628-20.766.052a.142.142 0 01-.158-.124.235.235 0 01.195-.266c7.073-1.065 14.25-1.504 21.398-.943C32.086.823 57.45 1.15 74.104 9.147c-2.557-1.23-3.931-5.405-3.882-7.596a.275.275 0 01.247-.288.354.354 0 01.316.186c1.903 3.495 4.455 6.069 8.04 8.785 1.472 1.114 2 2.658-1.063 2.127zm-54.7 12.19c-3.488 8.29 1.15 20.706 9.79 20.026 5.009-.393 8.762-4.918 8.333-9.952a12.715 12.715 0 00-3.554-7.767c-3.31-3.567-8.132-5.159-12.807-6.06a21.828 21.828 0 00-1.762 3.754zM24.764 49.63c-.85-.52-1.745-.964-2.673-1.326a.196.196 0 00-.23.069.2.2 0 00.005.242A3.969 3.969 0 0024.66 50a.197.197 0 00.194-.145.2.2 0 00-.09-.225z"
      ></path>
      <path
        fill="#fff"
        d="M19.928 46.699c-3.209-3.746-5.583-8.567-5.946-13.96 0-.112-.165-.102-.192 0-1.348 5.306 1.7 11.418 6.015 14.099a.095.095 0 00.114-.023.097.097 0 00.009-.116zM51.687.641a19.635 19.635 0 016.417.472.064.064 0 00.07-.043.065.065 0 00-.028-.076c-1.903-1.152-4.427-1.291-6.49-.48a.067.067 0 00.031.127z"
      ></path>
    </svg>
  );
}

export default Icon;
