import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="30"
      fill="none"
      viewBox="0 0 49 30"
    >
      <path
        fill="#fff"
        d="M47.157 7.417c-2.391-.416-4.824.32-7.081 1.178a.113.113 0 01-.148-.079.218.218 0 01.097-.24 14.413 14.413 0 015.48-1.98c-9.702-2.938-22.899-3.76-29.576 5.363 2.916.46 5.913 1.413 8.037 3.596a8.753 8.753 0 012.5 4.548c.888 4.232-2.206 8.595-6.506 8.602-7.804.014-10.477-9.715-6.904-16.28-4.128-.463-8.325-.377-12.46.031a.085.085 0 01-.094-.074.14.14 0 01.117-.16c4.244-.639 8.55-.902 12.838-.566C19.752.494 34.97.691 44.962 5.488c-1.534-.737-2.359-3.242-2.329-4.557a.165.165 0 01.148-.173.212.212 0 01.19.111c1.142 2.098 2.673 3.642 4.824 5.271.883.67 1.2 1.596-.638 1.277zm-32.82 7.314c-2.092 4.973.69 12.423 5.875 12.015 3.004-.235 5.257-2.95 4.999-5.97a7.629 7.629 0 00-2.132-4.661c-1.986-2.14-4.88-3.095-7.684-3.636a13.1 13.1 0 00-1.058 2.252zM15.359 29.778a10.114 10.114 0 00-1.604-.795.118.118 0 00-.139.041.12.12 0 00.004.145 2.38 2.38 0 001.677.831.118.118 0 00.116-.087.12.12 0 00-.054-.135z"
      ></path>
      <path
        fill="#fff"
        d="M12.456 28.02c-1.925-2.248-3.35-5.14-3.567-8.377 0-.067-.1-.06-.116 0-.808 3.185 1.02 6.851 3.61 8.46a.057.057 0 00.082-.048.058.058 0 00-.009-.035zM31.512.385a11.78 11.78 0 013.85.283.038.038 0 00.041-.052.038.038 0 00-.016-.02c-1.142-.69-2.657-.774-3.895-.287a.04.04 0 00-.02.046.04.04 0 00.04.03z"
      ></path>
    </svg>
  );
}

export default Icon;
