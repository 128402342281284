/** @format */

import * as React from "react";
import Drawer from "@mui/material/Drawer";
import MenuClose from "assets/icons/MenuClose";
import HomeIcon from "assets/icons/Home";
import PurchaseCreditIcon from "assets/icons/PurchaseCredit";
import HistoryIcon from "assets/icons/HistoryIcon";
import CreditIcon from "assets/icons/CreditIcon";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "store/useAppStore";
import LogoutIcon from "@mui/icons-material/Logout";
interface NavBarProps {
   open: boolean;
   closeMenu: () => void;
}

export default function Navbar({ open, closeMenu }: NavBarProps) {
   const credits = useAppStore().credits;
   const navigate = useNavigate();
   const navItems = [
      {
         title: "Home",
         icon: <HomeIcon />,
         link: "/uploadimage",
      },
      {
         title: "History",
         icon: <HistoryIcon />,
         link: "/history",
      },
      {
         title: "Purchase more credits",
         icon: <PurchaseCreditIcon />,
         link: "/purchase",
      },
   ];

   const handleNavigate = (link: string) => {
      if (link === "/uploadimage") {
         window.location.href = "/uploadimage";
      } else {
         navigate(`${link}`);
      }
      closeMenu();
   };

   const handleLogout = () => {
      localStorage.clear();
      window.location.href = "/";
   };

   const DrawerList = (
      <div className="w-[18rem] h-full pt-[0.9rem] pl-[1rem] relative">
         <div
            onClick={closeMenu}
            className="">
            <MenuClose />
         </div>
         <div className="text-[#fff] text-[1.1rem] flex flex-col  gap-[1.5rem] mt-[3rem]">
            {navItems.map((item, index) => (
               <div
                  onClick={() => handleNavigate(item.link)}
                  key={index}
                  className="flex items-center gap-4 cursor-pointer">
                  <span>{item.icon}</span>
                  {item.title}
               </div>
            ))}
            <div
               onClick={handleLogout}
               className="flex gap-3 items-center">
               <LogoutIcon sx={{ color: "#fff", fontSize: "1.5rem" }} />
               <span className="text-white text-[1.1rem]">Log out</span>
            </div>
         </div>
         <p className="text-[#fff] flex items-center text-[0.8rem] !absolute bottom-[13px] right-[15px]">
            <CreditIcon /> <span className="ml-2"> {credits} Credits Left</span>
         </p>
      </div>
   );

   return (
      <Drawer
         open={open}
         onClose={closeMenu}
         sx={{
            "& .MuiDrawer-paper": {
               backgroundColor: "rgba(0, 0, 0, 0.40)",
               boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
               backdropFilter: "blur(17px)",
               WebkitBackdropFilter: "blur(17px)",
               borderRadius: "0.8rem",
            },
         }}>
         {DrawerList}
      </Drawer>
   );
}
