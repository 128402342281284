/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="35"
         height="27"
         fill="none"
         viewBox="0 0 31 28">
         <path
            fill="url(#paint0_linear_2011_131)"
            fillRule="evenodd"
            d="M10.04.36a9.524 9.524 0 00-6.755 2.802 9.502 9.502 0 00-.735 12.62c2.405 2.976 9.007 8.875 11.74 11.28.665.585 1.75.583 2.412 0 2.688-2.382 9.132-8.185 11.745-11.275a9.5 9.5 0 00-.73-12.617 9.515 9.515 0 00-12.22-1.058A9.492 9.492 0 0010.04.365V.36z"
            clipRule="evenodd"></path>
         <defs>
            <linearGradient
               id="paint0_linear_2011_131"
               x1="0.5"
               x2="30.5"
               y1="13.93"
               y2="13.93"
               gradientUnits="userSpaceOnUse">
               <stop stopColor="#FC6A28"></stop>
               <stop
                  offset="1"
                  stopColor="#F94045"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}

export default Icon;
