import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 80 80"
    >
      <rect
        width="80"
        height="80"
        fill="#222327"
        fillOpacity="0.2"
        rx="40"
      ></rect>
      <path
        fill="#E1E1E1"
        d="M53.38 26.641a2.113 2.113 0 00-2.995 0L40 37.005 29.615 26.62a2.117 2.117 0 10-2.995 2.995L37.005 40 26.62 50.385a2.117 2.117 0 102.995 2.995L40 42.994 50.385 53.38a2.117 2.117 0 102.995-2.995L42.994 40 53.38 29.615c.807-.807.807-2.166 0-2.974z"
      ></path>
    </svg>
  );
}

export default Icon;
