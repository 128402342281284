/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="16"
         height="14"
         fill="none"
         viewBox="0 0 19 16">
         <path
            fill="#E1E1E1"
            d="M17.813 12.75a1.188 1.188 0 01-1.188 1.188H2.375a1.187 1.187 0 01-1.188-1.188V5.625a1.188 1.188 0 011.188-1.188h1.392c.944 0 1.85-.375 2.517-1.043l.986-.984c.222-.222.523-.347.837-.348h2.784c.315 0 .617.126.84.348l.982.984a3.561 3.561 0 002.52 1.043h1.392a1.188 1.188 0 011.188 1.188v7.125zM2.374 3.25A2.375 2.375 0 000 5.625v7.125a2.375 2.375 0 002.375 2.375h14.25A2.375 2.375 0 0019 12.75V5.625a2.375 2.375 0 00-2.375-2.375h-1.392c-.63 0-1.234-.25-1.679-.696l-.983-.983a2.375 2.375 0 00-1.68-.696H8.109c-.63 0-1.234.25-1.679.696l-.983.983a2.375 2.375 0 01-1.68.696H2.376z"></path>
         <path
            fill="#E1E1E1"
            d="M9.5 11.563a2.969 2.969 0 110-5.938 2.969 2.969 0 010 5.938zm0 1.187a4.156 4.156 0 100-8.313 4.156 4.156 0 000 8.313zM3.562 6.219a.594.594 0 11-1.187 0 .594.594 0 011.188 0z"></path>
      </svg>
   );
}

export default Icon;
