/** @format */

import React from "react";
import FunArrow from "assets/icons/FunArrow";
import Image from "components/Image";
import OverLay from "assets/images/CaraoselOverlay.png";

interface CarouselCardWebProps {
   imageBefore: any;
   imageAfter: any;
}

const CarouselCardWeb = ({ imageBefore, imageAfter }: CarouselCardWebProps) => {
   const innerHeight = window.innerHeight;
   return (
      <div className="flex justify-center h-[40vh]">
         <div className={`absolute w-[80%] h-[120%] ${innerHeight < 750 ? "top-[-1.5rem]" : "top-[-4rem]"} -z-10`}>
            <Image
               imageSrc={OverLay}
               altText="Overlay"
            />
         </div>
         <div className="w-[15rem] xl:h-[18rem] h-[15rem] ">
            <Image
               className="rounded-2xl"
               imageSrc={imageBefore}
               altText="demo"
            />
            <p className="text-white text-[0.9rem] font-medium mt-[0.5rem]">Before</p>
         </div>
         <span className="flex items-center mt-[-1.5rem]">
            <FunArrow />
         </span>
         <div className="w-[15rem] xl:h-[18rem] h-[15rem] ">
            <Image
               className="rounded-2xl"
               imageSrc={imageAfter}
               altText="demo"
            />
            <p className="text-white text-[0.9rem] font-medium mt-[0.5rem]">After</p>
            {/* <div className="absolute w-[70%] h-[120%] top-[-4rem] right-[-1rem] -z-10">
               <Image
                  imageSrc={OverLay}
                  altText="Overlay"
               />
            </div> */}
         </div>
      </div>
   );
};

export default CarouselCardWeb;
