/** @format */

import axios from "axios";
import { ROUTE_BASED_URL } from "../definition";
import { endPoint } from "./helperPaths";
import { PayloadProps } from "./helperPayloads";
import { trackPromise } from "react-promise-tracker";

const BASE_URL = ROUTE_BASED_URL;

const getToken = () => {
   return localStorage.getItem("token");
};

const headers = {
   "Content-Type": "application/json",
   "ngrok-skip-browser-warning": "any",
   Authorization: `Bearer ${getToken()}`,
};

export const GetAPI = (endPoint: endPoint) => {
   return new Promise((resolve, reject) => {
      trackPromise(
         axios
            .get(`${BASE_URL}${endPoint}`, {
               headers: headers,
            })
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            }),
      );
   });
};

export const PostAPI = (endPoint: endPoint, payload: PayloadProps) => {
   return new Promise((resolve, reject) => {
      trackPromise(
         axios
            .post(`${BASE_URL}${endPoint}`, payload, { headers: headers })
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            }),
      );
   });
};

export const PutAPI = (endPoint: endPoint, payload: any) => {
   return new Promise((resolve, reject) => {
      trackPromise(
         axios
            .put(`${BASE_URL}/${endPoint}`, payload, { headers: headers })
            .then((response) => {
               resolve(response);
            })
            .catch((error) => reject(error)),
      );
   });
};

export const DeleteAPI = (endPoint: endPoint) => {
   return new Promise((resolve, reject) => {
      trackPromise(
         axios
            .delete(`${BASE_URL}/${endPoint}`, { headers: headers })
            .then((response) => {
               resolve(response);
            })
            .catch((error) => reject(error)),
      );
   });
};

export const PostFormDataAPI = (endPoint: endPoint, payload: PayloadProps) => {
   return new Promise((resolve, reject) => {
      axios
         .post(`${BASE_URL}${endPoint}`, payload, {
            headers: {
               "Content-Type": "multipart/form-data",
               "ngrok-skip-browser-warning": "any",
               Authorization: `Bearer ${getToken()}`,
            },
         })
         .then((response) => {
            resolve(response);
         })
         .catch((error) => reject(error));
   });
};
