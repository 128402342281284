/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="36"
         height="26"
         fill="none"
         viewBox="0 0 33 27">
         <path
            fill="#FFD900"
            d="M30.86 8.09a2.875 2.875 0 00-3.158-.101l-3.718 2.23a.407.407 0 01-.319.045.41.41 0 01-.257-.195l-4.375-7.657a2.916 2.916 0 00-5.065 0L9.593 10.07a.41.41 0 01-.257.195.407.407 0 01-.32-.044L5.3 7.987A2.919 2.919 0 00.928 11.01l2.426 13.343.002.002a2.916 2.916 0 002.87 2.394h20.55a2.919 2.919 0 002.87-2.396l2.427-13.343a2.89 2.89 0 00-1.212-2.922z"></path>
      </svg>
   );
}

export default Icon;
