/** @format */

import React, { useEffect, useState } from "react";
import PurchaseIcon from "assets/icons/Purchase";
import PremiumIcon from "assets/icons/Premium";
import HeartIcon from "assets/icons/Heart";
import PurchaseBadge from "assets/icons/PurchaseBadge";
import { useAppStore } from "store/useAppStore";

interface PurchaseCardProps {
   credits: number;
   amount: number;
   totalImages: number;
   currency: string;
   recommended?: boolean;
   handleClick: any;
}

const PurchaseCard = ({ credits, amount, currency, totalImages, recommended, handleClick }: PurchaseCardProps) => {
   return (
      <div>
         <div
            onClick={() => handleClick(credits)}
            className="relative text-[#fff] bg-[#222327] md:rounded-md rounded-2xl grid grid-cols-[0.1fr,1fr,0.1fr] place-items-center px-[1.4rem] py-[0.8rem] md:py-[1.5rem] md:my-[1.5rem] my-[1rem]">
            {recommended && (
               <span className="absolute top-0 left-0">
                  <PurchaseBadge />
               </span>
            )}{" "}
            {currency !== "$" && (amount === 49 || amount === 99) && <PurchaseIcon />}
            {currency !== "$" && amount === 499 && <PremiumIcon />}
            {currency !== "$" && amount === 999 && <HeartIcon />}
            {currency === "$" && amount <= 10 && <PurchaseIcon />}
            {currency === "$" && amount === 20 && <PremiumIcon />}
            {currency === "$" && amount === 50 && <HeartIcon />}
            <div className=" text-[0.8rem] tracking-wide justify-self-start ml-4">
               <p>
                  <span className="text-[1.1rem] font-medium ">{credits} credits </span>({totalImages} images)
               </p>
               <p>
                  {currency} {amount}
               </p>
            </div>
            <button className="text-[#86A9E7] font-semibold active:text-[#4079da]">Buy</button>
         </div>
      </div>
   );
};

export default PurchaseCard;
