/** @format */

import React, { useState } from "react";
import { useAppStore } from "store/useAppStore";
import DownloadIcon from "assets/icons/DownloadIcon";
import ShareIcon from "assets/icons/ShareIcon";
import { handleDownload, handleShareFile } from "utils/helper";
import Button from "components/Button";

interface HistoryCardProps {
   handleMobile: () => void;
}

const HistoryCard = ({ handleMobile }: HistoryCardProps) => {
   const generatedImageHistory = useAppStore().generatedImageHistory;
   const setPreviewImageUrl = useAppStore().setPreviewImageUrl;
   const [isLoaded, setIsLoaded] = useState(false);

   const handleDownloadImage = (images: string) => {
      handleDownload(images);
   };

   const handleShareFileImage = (images: string) => {
      handleShareFile(images);
   };

   const handleImagePreview = (images: string) => {
      handleMobile();
      setPreviewImageUrl(images);
   };

   return (
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 grid-cols-1 gap-y-[2rem]">
         {generatedImageHistory?.map((images, index) => (
            <div
               key={index}
               className="md:border-b sm:px-[1rem] lg:[2rem]">
               <div className="w-full lg:h-[22rem] h-[20rem] mx-auto">
                  <img
                     onClick={() => handleImagePreview(images)}
                     onLoad={() => setIsLoaded(true)}
                     className={`transition-opacity duration-700 ease-in-out ${isLoaded ? "opacity-100" : "opacity-0"} h-full w-full sm:rounded-xl rounded-sm -z-10`}
                     src={images}
                     alt="Generated Image"
                  />
               </div>
               <div className="flex gap-[1rem] w-[95%] mx-auto mt-[1.2rem] pb-[1.3rem] max-md:border-b">
                  <Button
                     buttonText={"Download"}
                     icon={<DownloadIcon />}
                     onclick={() => handleDownloadImage(images)}
                  />
                  <Button
                     buttonText={"Share"}
                     icon={<ShareIcon />}
                     onclick={() => handleShareFileImage(images)}
                  />
               </div>
            </div>
         ))}
      </div>
   );
};

export default HistoryCard;
