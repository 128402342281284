import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      fill="none"
      viewBox="0 0 13 14"
    >
      <path
        fill="#fff"
        d="M5.708 10.167V3.715L3.649 5.773 2.541 4.625 6.499.667l3.959 3.958-1.109 1.148-2.058-2.058v6.452H5.708zm-3.959 3.166c-.435 0-.808-.155-1.117-.464a1.528 1.528 0 01-.466-1.119V9.375h1.583v2.375h9.5V9.375h1.584v2.375c0 .435-.155.808-.465 1.119-.31.31-.683.465-1.119.464h-9.5z"
      ></path>
    </svg>
  );
}

export default Icon;
