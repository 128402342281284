/** @format */

import React, { useEffect, useState } from "react";
import UploadButton from "components/UploadButton";
import CategoryChips from "components/Chips";
import ReferenceImage from "components/ReferenceImages";
import { useAppStore } from "store/useAppStore";
import DownloadIcon from "../../assets/icons/DownloadIcon";
import ShareIcon from "../../assets/icons/ShareIcon";
import Button from "components/Button";
import GenerateImageFrame from "components/GenerateImageFrame";
import GradientButton from "components/GradientButton";
import HowItWorksModalWeb from "components/HowItWorksWeb";

interface Category {
   [key: string]: string[];
}
interface WebUploadImageScreenProps {
   selectedCategory: any;
   handleSelectedImage: (url: string) => void;
   handleUpload: (event: any) => void;
   selectedImageStyle: string;
   uploadedImage: string;
   setUploadImageUrl: any;
   setSelectedCategory: any;
   generatedImage: string;
   downloadImage: () => void;
   shareImage: () => void;
   selectedImageCategory: any;
   enableButton: boolean;
   generateImage: () => void;
   chipsSelected: string;
   showGenerateBtn: boolean;
   setShowGenerateBtn: any;
}

const WebUploadImageScreen = ({
   selectedCategory,
   generatedImage,
   setUploadImageUrl,
   setSelectedCategory,
   handleSelectedImage,
   handleUpload,
   selectedImageStyle,
   uploadedImage,
   downloadImage,
   shareImage,
   selectedImageCategory,
   enableButton,
   generateImage,
   chipsSelected,
   showGenerateBtn,
   setShowGenerateBtn,
}: WebUploadImageScreenProps) => {
   const generatedImageUrl = useAppStore()?.generatedImageUrl;
   const uploadedImageUrl = useAppStore().uploadedImageUrl;
   const UpdateGeneratedImage = useAppStore().updateGeneratedImage;
   const [isLoaded, setIsLoaded] = useState(false);

   useEffect(() => {
      if (selectedImageStyle && generatedImageUrl) {
         setShowGenerateBtn(true);
      }
   }, [selectedImageStyle]);

   useEffect(() => {
      if (!uploadedImageUrl) {
         UpdateGeneratedImage();
         setShowGenerateBtn(false);
      }
   }, [uploadedImageUrl]);

   return (
      <div className="h-[100vh]">
         <HowItWorksModalWeb />
         <div className="h-[8vh] w-full grid grid-cols-[1fr,1fr,2fr]">
            <div className="bg-[#222327] w-full h-full !border-r"></div>
            <div className="bg-[#222327] w-full h-full border-r"></div>
            <div className="bg-[#000] w-full h-full"></div>
         </div>
         <div className="w-full h-[92vh] grid grid-cols-[1fr,1fr,2fr]">
            <div className="w-full h-[92vh] overflow-y-auto">
               <div className="bg-[#222327] w-full h-full overflow-y-auto border-r flex flex-col 2xl:gap-[5.5rem] gap-[2rem]">
                  <UploadButton
                     imageUrl={uploadedImage}
                     handleUpload={(event: any) => handleUpload(event)}
                     setUploadImageUrl={setUploadImageUrl}
                  />
                  <div className="2xl:w-[22rem] xl:w-[18rem] md:w-[13rem] bg-[#000] 2xl:mt-[1rem] text-[0.8rem] text-white flex flex-col justify-center p-3 w-[93%] mx-auto rounded-2xl">
                     <div className="mb-[0.7rem]">
                        <span>💡</span>
                        <span>Tips for best image output:</span>
                     </div>
                     <span className="mb-1">No Blurred Images</span>
                     <span>Wide angle shots are best</span>
                  </div>
                  <div className="px-[1.5rem] pb-[2rem] 2xl:mt-[-2rem]">
                     <p className="text-[#fff] tracking-wide mb-[0.8rem]">Select the type of interior</p>
                     <div className="flex flex-wrap gap-3">
                        {selectedCategory.map((item: any, index: number) => (
                           <CategoryChips
                              isSelected={chipsSelected === item}
                              key={index}
                              categorys={item}
                              handleCategory={(category: string) => setSelectedCategory(category)}
                           />
                        ))}
                     </div>
                  </div>
               </div>
            </div>
            <div className="bg-[#222327] w-full h-[92vh] px-[1rem] pt-[1.5rem] border-r">
               <p className="text-[#fff] tracking-wide text-[1.2rem] mb-[0.8rem] font-medium">Select a style</p>
               <div className="grid grid-cols-2 place-items-center gap-3 max-h-[92%] lg:mb-[3rem] overflow-y-auto rounded-2xl !scrollbar-hide">
                  {selectedImageCategory?.map((item: any, index: number) => (
                     <div>
                        <ReferenceImage
                           key={index}
                           imageUrl={item}
                           selectedImageStyle={(url: string) => handleSelectedImage(url)}
                           isSelected={selectedImageStyle === item}
                        />
                     </div>
                  ))}
               </div>
            </div>
            {generatedImageUrl ? (
               <div className="bg-black w-full h-[92vh] py-[1rem] flex flex-col justify-around items-center">
                  <p className="text-white text-[1.6rem]">Generated Image</p>
                  <div className="w-[75%] h-[80%] ">
                     <img
                        onLoad={() => setIsLoaded(true)}
                        src={generatedImageUrl}
                        className={`transition-opacity duration-700 ease-in-out ${isLoaded ? "opacity-100" : "opacity-0"} rounded-md h-full w-full `}
                        alt="Generated Image"
                     />
                  </div>
                  {!showGenerateBtn ? (
                     <div className="flex w-[80%] gap-[1.5rem]">
                        <Button
                           buttonText={"Download"}
                           icon={<DownloadIcon />}
                           onclick={downloadImage}
                        />
                        <Button
                           buttonText={"Share"}
                           icon={<ShareIcon />}
                           onclick={shareImage}
                        />
                     </div>
                  ) : (
                     <GradientButton
                        disabled={enableButton}
                        onclick={generateImage}
                        classname={"!static w-[80%]"}
                        buttonText={"Generate Image - 10 Credits"}
                     />
                  )}
               </div>
            ) : (
               <div className="bg-black w-full h-[92vh] py-[1rem] flex flex-col justify-around items-center">
                  {enableButton && <div className="absolute bg-black bg-opacity-60 w-[50%] h-[92vh]"></div>}
                  {!generatedImageUrl && (
                     <GenerateImageFrame
                        enableButton={enableButton}
                        generateImage={generateImage}
                     />
                  )}
               </div>
            )}
         </div>
      </div>
   );
};

export default WebUploadImageScreen;
