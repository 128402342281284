/** @format */

import React, { useEffect, useState } from "react";
import HistoryImageCard from "components/HistoryCard";
import GradientText from "components/GradientText";
import { useAppStore } from "store/useAppStore";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";
import PreviewModal from "components/PreviewModal";
import { handleDownload, handleShareFile } from "utils/helper";

const History = () => {
   const navigate = useNavigate();
   const getAllGeneratedImages = useAppStore().getAllGeneratedImages;
   const [isPreviewModalOpen, setIspreviewModalOpen] = useState(false);

   useEffect(() => {
      trackPromise(getAllGeneratedImages());
   }, []);

   const handleNavigate = () => {
      navigate(`/uploadimage`);
   };

   const handleMobile = () => {
      if (window.innerWidth < 769) {
         navigate(`/preview`);
      } else {
         setIspreviewModalOpen(true);
         document.body.style.overflow = "hidden";
      }
   };

   const handleModalClose = () => {
      setIspreviewModalOpen(false);
      document.body.style.overflow = "auto";
   };

   return (
      <div className="min-h-[100vh] flex flex-col lg:px-[2rem] px-[1rem] pb-[2rem] md:mt-[6rem] mt-[4rem]">
         <div className="flex  justify-between items-center">
            <p className="text-[#fff] my-[1rem] text-[1.6rem] tracking-wide font-semibold sm:pl-[1rem]">History</p>
            <div
               onClick={handleNavigate}
               className="p-[0.1rem] rounded-full bg-gradient-to-r from-teal-400  to-pink-500">
               <div className="h-full w-full bg-black py-1.5 px-3 rounded-full text-white font-medium cursor-pointer">Generate Again</div>
            </div>
         </div>
         <HistoryImageCard handleMobile={handleMobile} />
         {isPreviewModalOpen && (
            <PreviewModal
               downloadImage={(imageUrl: string) => handleDownload(imageUrl)}
               shareImage={(imageUrl: string) => handleShareFile(imageUrl)}
               handleModalClose={handleModalClose}
            />
         )}
      </div>
   );
};

export default History;
