/** @format */

import { useCreditsStore, useCreditsStoreProps } from "./useCreditStore";
/** @format */

import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { useInteriorDesignProps, useInteriorDesignStore } from "./UseInteriorDesignStore";
interface AppProps extends useInteriorDesignProps, useCreditsStoreProps {}
export const useAppStore = create<AppProps>()(
   devtools(
      persist(
         (set, get) => ({
            ...useInteriorDesignStore(set, get),
            ...useCreditsStore(set, get),
         }),
         {
            name: "interior-design-store",
            getStorage: () => localStorage,
         },
      ),
   ),
);
