/** @format */
import { PostFormDataAPI } from "./../api/request";
import { GenerateIdeasProps } from "interfaces/payloads/GenerateIdeas";
import { GetAPI } from "api/request";

export interface useInteriorDesignProps {
   uploadedImageUrl: string;
   generatedImageHistory: [];
   generatedImageUrl: string;
   isNewUser: boolean;
   previewImageUrl: string;
   generatedImage: (formData: GenerateIdeasProps) => Promise<any>;
   getCategoriesAndStyles: () => Promise<any>;
   getAllGeneratedImages: () => Promise<any>;
   setUploadImageUrl: (imageUrl: string) => void;
   updateNewUser: (value: boolean) => void;
   updateGeneratedImage: () => void;
   setPreviewImageUrl: (imageUrl: string) => void;
}

export const useInteriorDesignStore = (set: any, get: any): useInteriorDesignProps => ({
   isNewUser: false,
   uploadedImageUrl: "",
   generatedImageHistory: [],
   generatedImageUrl: "",
   previewImageUrl: "",
   updateNewUser: (value: boolean) => {
      set({
         isNewUser: value,
      });
   },
   setUploadImageUrl: (imageUrl: string) => {
      set({
         uploadedImageUrl: imageUrl,
      });
   },
   generatedImage: (formData: any) => {
      return new Promise((resolve, reject) => {
         PostFormDataAPI(`imagine_interior/images/create_image`, formData)
            .then((response: any) => {
               const imageUrl = response.data.image_url;
               set({
                  generatedImageUrl: imageUrl,
               });
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   getCategoriesAndStyles: async () => {
      return new Promise((resolve, reject) => {
         GetAPI(`imagine_interior/categories_and_styles`)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   getAllGeneratedImages: async () => {
      return new Promise((resolve, reject) => {
         GetAPI(`imagine_interior/images/get_customer_images`)
            .then((response: any) => {
               const cloudinaryUrls = response?.data?.map((image: any) => image.cloudinary_url);
               set({
                  generatedImageHistory: cloudinaryUrls,
               });
               resolve(cloudinaryUrls);
            })
            .catch((error: any) => {
               reject(error);
            });
      });
   },
   updateGeneratedImage: async () => {
      set({
         generatedImageUrl: "",
      });
   },
   setPreviewImageUrl: (imageUrl: string) => {
      set({
         previewImageUrl: imageUrl,
      });
   },
});
