/** @format */
import useRazorpay from "react-razorpay";
import { RAZORPAY_KEY } from "definition";

export const generateRazorpayPayload = (attemptResponse: any) => {
   const options = {
      key: RAZORPAY_KEY,
      amount: attemptResponse?.data?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "PixelGen",
      description: "",
      image: "https://example.com/your_logo",
      order_id: attemptResponse?.data?.payment_gateway_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      theme: {
         color: "#3399cc",
      },
   };
   return options;
};
