/** @format */

import React, { useState, useEffect } from "react";
import { CancelOutlined } from "@mui/icons-material";
import HowWorksImageOne from "assets/images/HowWorksDemoOne.png";
import HowWorksDemoTwo from "assets/images/HowWorksDemoTwo.png";
import HowWorksDemoThree from "assets/images/HowWorksDemoThree.png";
import HowWorksDemoButton from "assets/images/HowWorksDemoButton.png";
import FunArrowDown from "assets/icons/FunArrowDown";
import { useAppStore } from "store/useAppStore";
import { useLocation } from "react-router-dom";

export default function HowItWorksModal() {
   const isNewUser = useAppStore().isNewUser;
   const updateNewUser = useAppStore().updateNewUser;
   const location = useLocation();
   const showTourCards = [
      { title: "Upload/click a picture of your interior", image: HowWorksImageOne, delay: 0, icon: <FunArrowDown /> },
      { title: "Select the type of the interior", image: HowWorksDemoTwo, delay: 1000, icon: <FunArrowDown /> },
      { title: "Select a style you want to generate", image: HowWorksDemoThree, delay: 2000, icon: <FunArrowDown /> },
      { title: " Click on “Generate Ideas”.", image: HowWorksDemoButton, delay: 3000 },
   ];
   const [open, setOpen] = useState(false);

   useEffect(() => {
      if (isNewUser && location.pathname === "/uploadimage") {
         setOpen(true);
      }
      return () => {
         updateNewUser(false);
         setOpen(false);
      };
   }, []);

   const handleModal = () => {
      setOpen(!open);
   };

   return (
      <>
         {open && (
            <div className="w-full h-full p-[1rem] bg-[#000] fixed z-50 top-0">
               <div className="z-200 absolute overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[95%] h-[97%] bg-gray-500 bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-30 border-[0.2px] rounded-3xl border-gray-100">
                  <div className="text-white flex items-center justify-between px-[1rem] py-[0.7rem]">
                     <p className="font-semibold text-[1.3rem] ">How it works?</p>
                     <span onClick={handleModal}>
                        <CancelOutlined />
                     </span>
                  </div>
                  <div className="flex flex-col w-full pb-[1rem] rounded-3xl px-[0.5rem]">
                     {showTourCards.map((item, index) => (
                        <div
                           className={`w-full rounded-3xl px-[0.5rem] relative flex flex-col fade-up`}
                           style={{ animationDelay: `${index * 0.6}s` }}>
                           <p className="text-white font-medium mb-2">
                              <span className="font-semibold text-[0.9rem]">Step {index + 1} - </span> {item.title}
                           </p>
                           <div className="flex justify-center items-center w-full h-full">
                              <img
                                 className="h-full w-full"
                                 src={item.image}
                                 alt="demo"
                              />
                           </div>
                           <div className="flex justify-center">{item.icon}</div>
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         )}
      </>
   );
}
