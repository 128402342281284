/** @format */

import React from "react";
import FunArrow from "assets/icons/FunArrow";

interface CarouselCardProps {
   imageBefore: any;
   imageAfter: any;
}

const CarouselCard = ({ imageBefore, imageAfter }: CarouselCardProps) => {
   return (
      <div className="flex  gap-1 w-[95%] justify-center mx-auto max-md:h-[15rem]">
         <div className="md:w-[20rem] md:h-[16rem] w-[9.3125rem] h-[12.5rem] rounded-2xl">
            <img
               className="w-full h-[90%] rounded-2xl"
               src={imageBefore}
               alt="demo"
            />
            <p className="text-white text-[0.8rem] font-medium mt-[0.5rem]">Before</p>
         </div>
         <span className="flex items-center mt-[-1.5rem]">
            <FunArrow />
         </span>
         <div className="md:w-[20rem] md:h-[16rem] w-[9.3125rem] h-[12.5rem] rounded-2xl">
            <img
               className="w-full h-[90%] rounded-2xl"
               src={imageAfter}
               alt="demo"
            />
            <p className="text-white text-[0.8rem] font-medium mt-[0.5rem]">After</p>
         </div>
      </div>
   );
};

export default CarouselCard;
