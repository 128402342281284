/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="22"
         height="22"
         fill="none"
         viewBox="0 0 24 24">
         <path
            fill="#D90429"
            d="M20.344 17.156h-1.648a.37.37 0 00-.288.136c-.164.2-.34.392-.525.574a8.29 8.29 0 01-5.874 2.43 8.268 8.268 0 01-5.873-2.43 8.28 8.28 0 01-1.781-2.636A8.216 8.216 0 013.703 12a8.257 8.257 0 012.433-5.866 8.293 8.293 0 015.873-2.43c1.123 0 2.21.217 3.232.65a8.292 8.292 0 013.167 2.354c.07.087.178.136.288.136h1.648a.187.187 0 00.157-.288 10.082 10.082 0 00-8.517-4.634A10.067 10.067 0 001.922 12.103c.056 5.52 4.551 9.975 10.087 9.975 3.565 0 6.697-1.847 8.492-4.633a.187.187 0 00-.157-.289zm2.083-5.304l-3.325-2.625a.188.188 0 00-.305.148v1.781h-7.36a.188.188 0 00-.187.188v1.312c0 .103.084.188.188.188h7.359v1.781c0 .157.183.246.305.148l3.325-2.625a.188.188 0 000-.296z"></path>
      </svg>
   );
}

export default Icon;
