/** @format */

import React, { useEffect, useState } from "react";
import UploadButton from "components/UploadButton";
import CategoryChips from "components/Chips";
import ReferenceImage from "components/ReferenceImages";
import GradientButton from "components/GradientButton";
import { GenerateIdeasProps } from "interfaces/payloads/GenerateIdeas";
import { useAppStore } from "store/useAppStore";
import { useNavigate } from "react-router-dom";
import Compressor from "compressorjs";
import { trackPromise } from "react-promise-tracker";
import HowItWorksModal from "components/HowItWorks";
import { handleDownload, handleShareFile } from "utils/helper";
import WebUI from "./WebUI";
interface Category {
   [key: string]: string[];
}

const UploadImage = () => {
   const generatedImage = useAppStore().generatedImage;
   const generatedImageUrl = useAppStore()?.generatedImageUrl;
   const getCategoriesAndStyles = useAppStore().getCategoriesAndStyles;
   const setUploadImageUrl = useAppStore().setUploadImageUrl;
   const getRemainingCredits = useAppStore().getRemainingCredits;
   const availableCredits = useAppStore().credits;
   const UploadedImageUrl = useAppStore().uploadedImageUrl;
   const [category, setCategory] = useState<Category>({});
   const [selectedCategory, setSelectedCategory] = useState("kitchen");
   const [selectedImageUrl, setSelectedImageUrl] = useState("");
   const [enableButton, setEnableButton] = useState(true);
   const [showGenerateBtn, setShowGenerateBtn] = useState(false);
   const navigate = useNavigate();
   const innerWidth = window.innerWidth;

   useEffect(() => {
      if (UploadedImageUrl && selectedCategory && selectedImageUrl) {
         setEnableButton(false);
      } else {
         setEnableButton(true);
      }
   }, [UploadedImageUrl, selectedCategory, selectedImageUrl]);

   const handleGenerateIdeas = async () => {
      var formData = new FormData();
      if (UploadedImageUrl.startsWith("data:")) {
         const fetchResponse = await fetch(UploadedImageUrl);
         const blob = await fetchResponse.blob();
         formData.append("actual_image", blob, "image.jpg");
      } else {
         formData.append("actual_image", UploadedImageUrl);
      }
      formData.append("reference_image_url", selectedImageUrl);
      trackPromise(
         generatedImage(formData as unknown as GenerateIdeasProps).then(() => {
            if (innerWidth < 769) {
               window.location.href = "/saveimage";
            }
            setShowGenerateBtn(false);
         }),
      );
   };

   const handleSelectedReferenceImage = (url: string) => {
      setSelectedImageUrl(url);
   };

   useEffect(() => {
      getRemainingCredits().then((response: any) => {
         const currentCredit = response.data.remaining_credits;
         if (currentCredit > 0) {
            getCategoriesAndStyles().then((response: any) => {
               setCategory(response.data);
               setSelectedCategory(Object.keys(response.data)[0]);
            });
         } else {
            navigate(`/purchase`);
         }
      });
   }, [availableCredits]);

   const handleFileChange = (event: any) => {
      const file = event.target.files ? event.target.files[0] : null;
      if (file) {
         new Compressor(file, {
            quality: 0.6,
            success(result: any) {
               const reader = new FileReader();
               reader.onloadend = () => {
                  const base64String = reader.result;
                  setUploadImageUrl(base64String as string);
               };
               reader.readAsDataURL(result);
            },
            error(error: any) {
               console.log(error.message);
            },
         });
      }
   };

   const handleDownloadImage = () => {
      handleDownload(generatedImageUrl);
   };

   const handleShareFileImage = () => {
      handleShareFile(generatedImageUrl);
   };

   return (
      <>
         <div className="md:hidden">
            {availableCredits > 0 && (
               <div className="flex flex-col gap-[1.5rem] mt-[4rem]">
                  <HowItWorksModal />
                  <UploadButton
                     imageUrl={UploadedImageUrl}
                     handleUpload={(event: any) => handleFileChange(event)}
                     setUploadImageUrl={setUploadImageUrl}
                  />
                  <div className="mt-[-0.7rem] w-[16rem] bg-[#222327] text-white flex flex-col justify-center text-[0.7rem]  p-3 mx-auto rounded-2xl">
                     <div className="mb-[0.4rem]">
                        <span>💡</span>
                        <span className="font-medium">Tips for best image output:</span>
                     </div>
                     <span className="mb-1">•No Blurred Images</span>
                     <span>• Wide angle shots are best</span>
                  </div>
                  <div className="pb-[1rem] px-[0.9rem]">
                     <p className="text-[#fff] tracking-wide mb-[0.8rem]">Select the type of interior</p>
                     <div className="flex flex-wrap gap-3">
                        {Object.keys(category).map((item, index) => (
                           <CategoryChips
                              isSelected={selectedCategory === item}
                              key={index}
                              categorys={item}
                              handleCategory={(category: string) => setSelectedCategory(category)}
                           />
                        ))}
                     </div>
                  </div>
                  <div className="pb-[1rem] mt-[-0.5rem] px-[0.9rem]">
                     <p className="text-[#fff] tracking-wide mb-[0.8rem] ">Select a style</p>
                     <div className="grid grid-cols-2 place-items-center gap-3 mb-[2rem]">
                        {category[selectedCategory]?.map((item, index) => (
                           <ReferenceImage
                              key={index}
                              imageUrl={item}
                              selectedImageStyle={(url: string) => handleSelectedReferenceImage(url)}
                              isSelected={selectedImageUrl === item}
                           />
                        ))}
                     </div>
                  </div>
                  <div className="flex justify-center mb-[1rem]">
                     <GradientButton
                        disabled={enableButton}
                        onclick={handleGenerateIdeas}
                        classname={"w-[93%] "}
                        buttonText={"Generate Image - 10 Credits"}
                     />
                  </div>
               </div>
            )}
         </div>
         {availableCredits > 0 && (
            <div className="max-md:hidden">
               <WebUI
                  uploadedImage={UploadedImageUrl}
                  handleUpload={(event) => handleFileChange(event)}
                  selectedImageCategory={category[selectedCategory]}
                  chipsSelected={selectedCategory}
                  handleSelectedImage={(url: string) => handleSelectedReferenceImage(url)}
                  selectedImageStyle={selectedImageUrl}
                  selectedCategory={Object.keys(category)}
                  setUploadImageUrl={setUploadImageUrl}
                  setSelectedCategory={setSelectedCategory}
                  generatedImage={generatedImageUrl}
                  downloadImage={handleDownloadImage}
                  shareImage={handleShareFileImage}
                  enableButton={enableButton}
                  generateImage={handleGenerateIdeas}
                  showGenerateBtn={showGenerateBtn}
                  setShowGenerateBtn={setShowGenerateBtn}
               />
            </div>
         )}
      </>
   );
};

export default UploadImage;
