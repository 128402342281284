/** @format */

import React from "react";
import ImagesGalleryFrame from "assets/icons/ImagesGalleryFrame.svg";
import Image from "components/Image";
import GradientButton from "components/GradientButton";

interface GenerateImageFrameProps {
   enableButton: boolean;
   generateImage: () => void;
}

const GenerateImageFrame = ({ enableButton, generateImage }: GenerateImageFrameProps) => {
   return (
      <div className="w-full flex flex-col justify-center items-center gap-[1.5rem]">
         <div className="w-[50%]">
            <Image
               imageSrc={ImagesGalleryFrame}
               altText="Frame"
            />
         </div>
         <p className="text-white">Choose the prompts to generate your image</p>
         <GradientButton
            disabled={enableButton}
            onclick={generateImage}
            classname={"!static w-[40%]"}
            buttonText={"Generate Image - 10 Credits"}
         />
      </div>
   );
};

export default GenerateImageFrame;
