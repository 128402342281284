/** @format */

import React, { useState, useEffect } from "react";
import MenuOpen from "assets/icons/Menuopen";
import CreditIcon from "assets/icons/CreditIcon";
import Navbar from "../NavBar";
import { useAppStore } from "store/useAppStore";
import BackArrow from "assets/icons/BackArrow";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const credits = useAppStore().credits;

   const [isMenuOpen, setIsMenuOpen] = useState(false);
   const [isShowBackIcon, setIsShowBackIcon] = useState(false);

   const handleMenuOpen = () => {
      location.pathname === "/saveimage" ? (window.location.href = `/uploadimage`) : setIsMenuOpen(!isMenuOpen);
   };

   useEffect(() => {
      if (location.pathname === "/saveimage") {
         setIsShowBackIcon(true);
      }
      return () => {
         setIsShowBackIcon(false);
      };
   }, []);

   return (
      <div
         className="w-[100%] top-0 bg-black z-20"
         style={{ position: "fixed" }}>
         <Navbar
            open={isMenuOpen}
            closeMenu={handleMenuOpen}
         />
         <div className="h-[8vh] border-b-[0.1px] flex justify-between px-[0.7rem] py-[0.9rem]">
            <span onClick={handleMenuOpen}>{isShowBackIcon ? <BackArrow /> : <MenuOpen />}</span>
            <div className="flex items-center justify-center gap-2">
               <CreditIcon />
               <span className="text-[#fff] text-[0.8rem]">{credits} Credits Left</span>
            </div>
         </div>
      </div>
   );
};

export default Header;
