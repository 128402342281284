import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect
        width="31"
        height="31"
        x="0.5"
        y="0.5"
        stroke="#fff"
        rx="15.5"
      ></rect>
      <path
        fill="#fff"
        d="M21.999 15.143H11.834l3.11-3.736a.858.858 0 10-1.319-1.097L9.34 15.452c-.03.04-.055.084-.077.129 0 .042 0 .068-.06.111a.857.857 0 00-.06.308c0 .106.02.21.06.31 0 .042 0 .068.06.11.022.045.048.088.077.13l4.285 5.142a.857.857 0 001.209.11.857.857 0 00.11-1.208l-3.11-3.736h10.165a.857.857 0 100-1.715z"
      ></path>
    </svg>
  );
}

export default Icon;
