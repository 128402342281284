/** @format */

import React, { useEffect, useState, useRef } from "react";
import Image from "components/Image";
import Logout from "assets/icons/Logout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStore } from "store/useAppStore";
import CreditIcon from "assets/icons/CreditIcon";

export default function WebNavbar() {
   const location = useLocation();
   const navigate = useNavigate();
   const credits = useAppStore().credits;
   const navLinkStyles = "hover:text-[#86A9E7] active:text-[#4079da] cursor-pointer font-medium transition-colors duration-300";
   const activeLinkStyles = "text-[#86A9E7]";
   const [open, setOpen] = useState(false);
   const dropdownRef = useRef<HTMLDivElement>(null);

   const navLinks = [
      { text: "Home", path: "/uploadimage" },
      { text: "History", path: "/history" },
      { text: "Purchase Credits", path: "/purchase" },
   ];

   const handleLogout = () => {
      localStorage.clear();
      window.location.href = "/";
   };

   useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
         // Specify MouseEvent type for event
         if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node as any)) {
            // Cast event.target to Node
            setOpen(false);
         }
      }
      document.addEventListener("click", handleClickOutside);
      return () => {
         document.removeEventListener("click", handleClickOutside);
      };
   }, []);

   useEffect(() => {
      setOpen(false);
   }, [location.pathname]);

   const handleOpen = () => {
      setOpen(!open);
   };

   return (
      <div className="bg-[#222327] fixed top-0 z-10 w-full text-white flex justify-between items-center py-[1.3rem] px-[2rem] 2xl:px-[3rem] border-b">
         <h2 className="text-[1.4rem] font-medium cursor-pointer">Imagine Interior</h2>
         <nav className="flex gap-[2rem]">
            {navLinks.map((link, index) => (
               <span
                  key={index}
                  onClick={() => navigate(link.path)}
                  className={`${navLinkStyles} ${location.pathname === link.path ? activeLinkStyles : ""}`}>
                  {link.text}
               </span>
            ))}
         </nav>
         <div
            className="flex items-center gap-[1.5rem] relative"
            ref={dropdownRef}>
            <span className="flex items-center gap-3">
               <CreditIcon />
               <p>{credits} Credits Left</p>
            </span>
            <div
               onClick={handleOpen}
               className="w-[2rem] h-[2rem] cursor-pointer">
               <Image
                  className="rounded-full"
                  imageSrc={"https://i.pinimg.com/originals/c0/4b/01/c04b017b6b9d1c189e15e6559aeb3ca8.png"}
                  altText="user"
               />
            </div>
            {open && (
               <div
                  style={{
                     borderTopRightRadius: "0px",
                  }}
                  className="bg-[#222327] tracking-wide absolute text-[1.1rem] top-11 right-5 z-50 flex flex-col justify-between items-center border rounded-xl py-4 px-7">
                  <span
                     style={{ borderBottom: "1px solid #fff" }}
                     className="mb-1.5 pb-3 cursor-pointer">
                     View Profile
                  </span>
                  <span
                     onClick={handleLogout}
                     className="text-[#D90429] mt-1 cursor-pointer flex items-center gap-2">
                     <Logout /> Log Out
                  </span>
               </div>
            )}
         </div>
      </div>
   );
}
