import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 13.004V14a3 3 0 003 3h10a3 3 0 003-3v-1M9 1.5v11m0 0L12.5 9M9 12.5L5.5 9"
      ></path>
    </svg>
  );
}

export default Icon;
