/** @format */

import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import UploadImage from "./pages/UploadImage";
import SaveImage from "./pages/SaveImage";
import Purchase from "./pages/Purchase";
import History from "./pages/History";
import Preview from "pages/Preview";
import Header from "./components/Header";
import { useEffect } from "react";
import Loader from "components/Loader";
import { useAppStore } from "store/useAppStore";
import { usePromiseTracker } from "react-promise-tracker";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import WebNavbar from "components/WebNavBar";

function App() {
   return (
      <BrowserRouter>
         <RoutesWithHeader />
      </BrowserRouter>
   );
}

function RoutesWithHeader() {
   const navigate = useNavigate();
   const location = useLocation();
   const innerWidth = window.innerWidth;
   const getRemainingCredits = useAppStore().getRemainingCredits;
   const { promiseInProgress } = usePromiseTracker();

   useEffect(() => {
      if (location.pathname !== "/") {
         getRemainingCredits();
         if (localStorage.getItem("token") === "undefined" || localStorage.getItem("token") === null) {
            navigate("/");
         }
      }
   }, []);

   useEffect(() => {
      AOS.init({
         duration: 2000,
         once: true,
      });
   }, []);

   const renderHeaderOrNavbar = () => {
      const innerWidth = window.innerWidth;
      if (location.pathname !== "/" && location.pathname !== "/preview" && !location.pathname.includes("/preview/")) {
         if (innerWidth <= 769) {
            return <Header />;
         } else {
            return <WebNavbar />;
         }
      }
      return null;
   };

   return (
      <div>
         <Loader isLoading={promiseInProgress} />
         <div className="App">
            {renderHeaderOrNavbar()}
            <Routes>
               <Route
                  path="/"
                  element={<Login />}
               />
               <Route
                  path="/uploadimage"
                  element={<UploadImage />}
               />
               <Route
                  path="/saveimage"
                  element={<SaveImage />}
               />
               <Route
                  path="/purchase"
                  element={<Purchase />}
               />
               <Route
                  path="/success/:paymentId"
                  element={<Purchase />}
               />
               <Route
                  path="/cancelled/:paymentId"
                  element={<Purchase />}
               />
               <Route
                  path="/history"
                  element={<History />}
               />
               {window.innerWidth < 769 && (
                  <>
                     <Route
                        path="/preview"
                        element={<Preview />}
                     />
                     <Route
                        path="/preview/:generatedimage"
                        element={<Preview />}
                     />
                  </>
               )}
            </Routes>
         </div>
      </div>
   );
}

export default App;
