/** @format */

import React from "react";

const ImageOverlayText = () => {
   return (
      <div className="text-white md:text-[#000] max-md:text-center !mx-auto">
         <p className="text-[1.4rem] md:text-[2rem] lg:text-[2.3rem] xl:text-[3rem] font-bold mb-[0.5rem] lg:mb-[1rem]">Imagine your interior</p>
         <p className="text-[0.7rem] md:text-[1.2rem] xl:text-[1.5rem] font-medium">Transform your space into a realm of </p>
         <p className="text-[0.7rem] md:text-[1.2rem] xl:text-[1.5rem] font-medium">imagination with the power of AI.</p>
      </div>
   );
};

export default ImageOverlayText;
