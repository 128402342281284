/** @format */

import React from "react";
import GoogleIcon from "assets/icons/GoogleIcon";

interface ButtonProps {
   handleClick: () => void;
   className?: string;
}

const LoginButton = ({ handleClick, className }: ButtonProps) => {
   return (
      <button
         onClick={handleClick}
         className={`${className} flex z-30  bg-[#1C1F20] active:bg-[#000] w-[70%] mx-auto justify-center items-center gap-[1rem] border-[#fff] border-2 px-[1.2rem] py-[0.7rem] rounded-full text-[#fff] `}>
         <GoogleIcon />
         <span>Sign in with Google</span>
      </button>
   );
};

export default LoginButton;
