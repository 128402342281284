/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         fill="none"
         viewBox="0 0 16 18">
         <path
            fill="#fff"
            d="M2.5 15.417h2.75v-5.5h5.5v5.5h2.75v-8.25L8 3.042 2.5 7.167v8.25zM.665 17.25v-11L7.999.75l7.334 5.5v11H8.916v-5.5H7.083v5.5H.666z"></path>
      </svg>
   );
}

export default Icon;
