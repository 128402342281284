/** @format */

import React from "react";

function Icon() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         fill="none"
         viewBox="0 0 22 16">
         <path
            fill="#fff"
            d="M13.75.667a7.333 7.333 0 110 14.666 7.333 7.333 0 010-14.666zm0 12.833a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM2.75 8a5.49 5.49 0 003.666 5.179v1.916C3.254 14.279.916 11.419.916 8c0-3.42 2.338-6.28 5.5-7.095V2.82A5.491 5.491 0 002.749 8z"></path>
      </svg>
   );
}

export default Icon;
