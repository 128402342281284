/** @format */

import { GetAPI } from "api/request";

export interface useCreditsStoreProps {
   credits: number;
   pricingCurrency: boolean;
   getRemainingCredits: () => Promise<any>;
   getPricing: () => Promise<any>;
   updateCredits: () => Promise<any>;
   setCredits: (initailCredits: number) => void;
   setPricingCurrency: (value: boolean) => void;
}
export const useCreditsStore = (set: any, get: any): useCreditsStoreProps => ({
   pricingCurrency: true,
   credits: 0,
   setCredits: (initialCredits: number) => {
      set({
         credits: initialCredits,
      });
   },
   setPricingCurrency: (value: boolean) => {
      set({
         pricingCurrency: value,
      });
   },
   updateCredits: async () => {
      return new Promise((resolve, reject) => {
         GetAPI("credits/current_credits")
            .then((response: any) => {
               set({
                  credits: response?.data?.no_of_credits,
               });
               resolve(response);
            })
            .catch((error) => reject(error));
      });
   },
   getRemainingCredits: async () => {
      return new Promise((resolve, reject) => {
         GetAPI(`imagine_interior/images/get_remaining_credits`)
            .then((response: any) => {
               set({
                  credits: response?.data?.remaining_credits,
               });
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   getPricing: async () => {
      return new Promise((resolve, reject) => {
         GetAPI(`imagine_interior/subscriptions/get_pricing_ids`)
            .then((response: any) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
});
