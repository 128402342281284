import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 80 80"
    >
      <rect
        width="79"
        height="79"
        x="0.5"
        y="0.5"
        fill="#222327"
        rx="39.5"
      ></rect>
      <rect
        width="79"
        height="79"
        x="0.5"
        y="0.5"
        stroke="#222327"
        rx="39.5"
      ></rect>
      <path
        fill="#E1E1E1"
        d="M49.333 55.555c-1.296 0-2.398-.453-3.305-1.36-.908-.908-1.361-2.01-1.361-3.306 0-.182.013-.37.039-.565.025-.195.064-.37.116-.524l-10.966-6.378c-.441.389-.934.694-1.478.915-.545.22-1.115.33-1.711.33-1.297 0-2.399-.454-3.306-1.362C26.454 42.398 26 41.296 26 40c0-1.296.454-2.398 1.361-3.306.907-.907 2.01-1.36 3.306-1.36.596 0 1.166.11 1.71.33.545.221 1.038.526 1.479.914L44.822 30.2a2.862 2.862 0 01-.116-.524 4.25 4.25 0 01-.04-.565c0-1.296.454-2.398 1.362-3.306.907-.907 2.009-1.36 3.305-1.36 1.297 0 2.398.453 3.306 1.36.907.908 1.361 2.01 1.361 3.306 0 1.296-.454 2.398-1.361 3.306-.908.907-2.01 1.36-3.306 1.36-.596 0-1.166-.11-1.71-.33a5.093 5.093 0 01-1.479-.914l-10.966 6.378c.052.156.09.33.116.526.026.195.04.382.04.563 0 .18-.014.369-.04.565-.025.196-.064.37-.116.524l10.966 6.378c.441-.39.934-.694 1.478-.914a4.551 4.551 0 011.711-.33c1.297 0 2.398.453 3.306 1.36.907.908 1.361 2.01 1.361 3.306 0 1.296-.454 2.398-1.361 3.305-.908.908-2.01 1.361-3.306 1.361z"
      ></path>
    </svg>
  );
}

export default Icon;
