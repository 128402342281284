/** @format */

import React, { useCallback, useRef, useState } from "react";
import UploadIcon from "../../assets/icons/UploadSymbolIcon";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Upload from "assets/icons/Upload";
import CameraIcon from "assets/icons/CameraIcon";

interface UploadButtonProps {
   imageUrl: string;
   handleUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
   setUploadImageUrl: (value: string) => void;
}

const UploadButton = ({ imageUrl, handleUpload, setUploadImageUrl }: UploadButtonProps) => {
   const [isLoaded, setIsLoaded] = useState(false);
   const fileInputRef = useRef<HTMLInputElement>(null);
   const cameraInputRef = useRef<HTMLInputElement>(null);

   const handleUploadClick = () => {
      fileInputRef.current?.click();
   };

   const handleCameraClick = () => {
      cameraInputRef.current?.click();
   };

   const handleRemoveImage = useCallback(() => {
      setIsLoaded(false);
      setUploadImageUrl("");
   }, []);

   return (
      <div className="pt-[2rem] xl:h-[18.75rem] max-md:h-[18.75rem] relative">
         {imageUrl ? (
            <div
               className="bg-black 2xl:w-[22rem] 2xl:h-[21rem] xl:w-[18rem] xl:h-[17rem] md:w-[13rem] md:h-[13rem] w-[16rem] h-[15rem] mx-auto flex flex-col p-1 justify-center items-center rounded-[22px] gap-[0.8rem] relative"
               style={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23FFFFFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='83' stroke-linecap='square'/%3e%3c/svg%3e")`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: "12px",
               }}>
               <img
                  onLoad={() => setIsLoaded(true)}
                  className={`w-[100%] h-[100%] rounded-[20px] transition-opacity duration-700 ease-in-out ${isLoaded ? "opacity-100" : "opacity-0"} `}
                  src={imageUrl}
                  alt="Uploaded"
               />
            </div>
         ) : (
            <div
               className="bg-black 2xl:w-[22rem] 2xl:h-[21rem] xl:w-[18rem] xl:h-[17rem] md:w-[13rem] md:h-[13rem] w-[16rem] h-[15rem] mx-auto flex flex-col px-[1rem] py-[1.5rem] justify-between items-center rounded-[20px] gap-[0.8rem] relative"
               style={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23FFFFFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='83' stroke-linecap='square'/%3e%3c/svg%3e")`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: "12px",
               }}>
               <div className="text-white flex flex-col items-center gap-2 xl:mt-[1.3rem] 2xl:mt-[3rem]">
                  <span>
                     <UploadIcon />
                  </span>
                  <span className="text-center max-xl:text-[0.7rem] max-md:text-[0.9rem]">Please upload a photo of a room in your house, such as your living room or bathroom.</span>
               </div>
               <div className="w-full flex justify-center items-center gap-3">
                  <button
                     onClick={handleUploadClick}
                     className="flex max-xl:flex-col justify-center items-center p-2 gap-2 border-[2.5px] rounded-2xl w-[90%] text-white font-medium active:bg-[#232323]">
                     <Upload /> <span className="text-[11.5px]">Upload Image</span>
                  </button>
                  {window.innerWidth < 769 && (
                     <button
                        onClick={handleCameraClick}
                        className="flex max-xl:flex-col justify-center items-center p-2 gap-2 border-[2.5px] rounded-2xl w-[90%] text-white font-medium active:bg-[#232323]">
                        <CameraIcon /> <span className="text-[11.5px]">Click a Picture</span>
                     </button>
                  )}
               </div>
               <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleUpload}
                  style={{ display: "none" }}
                  accept="image/*"
               />
               <input
                  type="file"
                  ref={cameraInputRef}
                  onChange={handleUpload}
                  style={{ display: "none" }}
                  accept="image/*"
                  capture="environment"
               />
            </div>
         )}
         {imageUrl && (
            <div
               onClick={handleRemoveImage}
               className="flex justify-center mt-2 md:absolute 2xl:bottom-[-3rem] xl:bottom-2 bottom-9 left-0 right-0">
               <span className="text-[#D90429] font-semibold flex gap-1 items-center cursor-pointer">
                  <RemoveCircleOutlineIcon />
                  Remove
               </span>
            </div>
         )}
      </div>
   );
};

export default UploadButton;
