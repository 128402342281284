/** @format */

import React from "react";

interface ButttonProps {
   buttonText: string;
   icon?: any;
   onclick: () => void;
   classname?: string;
}

const Button = ({ buttonText, icon, onclick, classname }: ButttonProps) => {
   return (
      <button
         onClick={onclick}
         className={`${classname} bg-black flex items-center justify-center gap-3 w-[100%] border-[#fff] border-2 text-[#fff] rounded-full p-2 hover:bg-[#1C1F20] active:bg-black`}>
         {icon}
         <span>{buttonText}</span>
      </button>
   );
};
export default Button;
